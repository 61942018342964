import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import SalesIndex from './join';

export default function (props) {
  useEffect(() => {
    navigate('/join/' + location.search);
  }, []);
  return <SalesIndex />;
}

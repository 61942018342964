import React, {useCallback, useState} from 'react';
import {navigate} from '@reach/router';
import classNames from 'classnames';
import ContactInfoForm from './components/ContactInfoForm';
import SegmentationForm from './components/SegmentationForm';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import './OptinForm.scss';

function OptinForm({
  id,
  contactFormHeader = null,
  linkColor = 'purple',
  variant = 'default',
  btnText = null,
  onComplete = () => {},
  ...props
}) {
  return (
    <ContactInfoForm
      onComplete={() => {
        onComplete();
      }}
      id={id}
      variant={variant}
      contactFormHeader={contactFormHeader}
      optinButtonText={btnText}
    />
  );
}
export default OptinForm;

export function OptinFormInline({
  variant = 'default',
  entry = 'default',
  disableNavigate = false,
  contactFormHeader = null,
  btnText = null,
  ...props
}) {
  const navigateUrl = '/workshop/broadcasts/';
  const onComplete = React.useCallback(() => {
    !disableNavigate && navigate(navigateUrl, {state: {fromEntry: entry}});
  }, []);
  return (
    <OptinForm
      onComplete={onComplete}
      id="inlineOptInForm"
      entry={entry}
      variant={variant}
      contactFormHeader={contactFormHeader}
      btnText={btnText}
    />
  );
}

import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {Link} from 'react-scroll';
import classNames from 'classnames';
import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent';
import {ListIcon} from 'components/Elements/IconList';
import useBalanceText from 'utils/useBalanceText';

export default function ModalExitMasterclassScreening() {
  const {isOpen, toggle} = useExitIntent(60000);
  return (
    <Modal className="modal-exit-intent modal-exit-intent-empowerment" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h5 className="section-heading text-center">Plant-Based Coaches are more in demand than ever.</h5>
          <p className="text-center">
            Do you want to help people, animals, <br className="d-none d-xl-block" />
            and the planet with deeply rewarding work <br className="d-none d-xl-block" />
            that spreads the food revolution?
          </p>
          <Button className="btn-exit text-uppercase text-white text-h6" color="cta" onClick={toggle} block>
            Watch The Masterclass Now
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div
            className={classNames(`exit-modal-image`)}
            style={{
              backgroundImage: `url('https://cdn.foodrevolution.org/pbcc/images/14751199560-exit.jpg')`
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
}

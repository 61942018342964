import React, {Component} from 'react';
import classNames from 'classnames';
import './CountdownTimer.scss';

function CountdownElement({value, label}) {
  return (
    <div className="countdown-element">
      <div className="countdown-digit">{value}</div>{' '}
      <span className="countdown-label">{label + (value > 1 ? 's' : '')}</span>
    </div>
  );
}

export default function CountdownTimer({className, onComplete = () => {}, time}) {
  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  React.useEffect(() => {
    let timer;
    const tick = () => {
      let diff = time - new Date().getTime();
      if (diff / 1000 < 1) {
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        return onComplete();
      }
      setDays(Math.floor(diff / 86400000));
      diff = diff % 86400000;
      setHours(Math.floor(diff / 3600000));
      diff = diff % 3600000;
      setMinutes(Math.floor(diff / 60000));
      setSeconds(Math.floor((diff % 60000) / 1000));
      timer = setTimeout(tick, 1000);
    };
    tick();
    return () => clearTimeout(timer);
  }, [time]);

  return (
    <div className={classNames('countdown-holder', className)}>
      <div className="countdown-timer">
        {days ? <CountdownElement value={days} label="day" /> : null}
        {days || hours ? <CountdownElement value={hours} label="hour" /> : null}
        {days || hours || minutes ? <CountdownElement value={minutes} label="min" /> : null}
        <CountdownElement value={seconds} label="sec" />
      </div>
    </div>
  );
}

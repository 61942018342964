import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import * as Sentry from '@sentry/browser';
const {SENTRY_DSN} = process.env;
const {RELEASE = 'test'} = process.env;
// Your top level component
import App from './App';

// Export your top level component as JSX (for static rendering)
export default App;

// Render your app
if (typeof document !== 'undefined') {
  if (window.location.search.includes('%%__AdditionalEmailAttribute')) {
    window.location.search = window.location.search
      .replace('%%__AdditionalEmailAttribute1%%', '')
      .replace('%%__AdditionalEmailAttribute2%%', '');
  }
  SENTRY_DSN && Sentry.init({dsn: SENTRY_DSN, release: RELEASE});
  const target = document.getElementById('root');

  const renderMethod = target.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render;

  const render = (Comp) => {
    renderMethod(
      <AppContainer>
        <React.Suspense fallback={<em>Loading...</em>}>
          <Comp />
        </React.Suspense>
      </AppContainer>,
      target
    );
  };

  // Render!
  render(App);

  // Hot Module Replacement
  if (module && module.hot) {
    module.hot.accept('./App', () => {
      render(App);
    });
  }
}

import React, {useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import Angle, {AngleTop} from 'components/Elements/Angle';
import {Collapse} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown as faChevronCircleDownRegular} from '@fortawesome/pro-regular-svg-icons/faChevronCircleDown';
import {faChevronCircleUp as faChevronCircleUpRegular} from '@fortawesome/pro-regular-svg-icons/faChevronCircleUp';
import './Section.scss';

export default function Section({angle = 'bottom', id, color, ...props}) {
  return (
    <section id={id} className={classNames(`section background-${color}`, props.className)}>
      {angle === 'top' || angle === 'both' ? (
        <AngleTop
          className={classNames(`angle-top`, props.angleTopClass)}
          angleWrapClass={props.angleWrapClass}
          color={color}
        />
      ) : null}
      <div className={classNames(`section-inner section-inner-${angle}`, props.innerClass)}>{props.children}</div>
      {angle === 'bottom' || angle === 'both' ? (
        <Angle
          className={classNames(`angle-bottom`, props.angleClass)}
          angleWrapClass={props.angleWrapClass}
          color={color}
        />
      ) : null}
    </section>
  );
}

export function SectionImage({
  id,
  color,
  imgPosition = 'left',
  imgBgPosition = 'center',
  colClass = 'col-12 col-lg-7',
  children,
  ...props
}) {
  let stylesMobile = {
    backgroundImage: `url('${props.imgMobileUrl ? props.imgMobileUrl : props.imgUrl}')`
  };

  let stylesDesktop = {
    backgroundImage: `url('${props.imgUrl}')`
  };

  let row = `d-flex justify-content-${imgPosition === 'left' ? 'end' : 'start'}`;

  let sectionWithTitle = props.title ? `section-image-${imgPosition}-with-title` : null;

  return (
    <section id={id} className={classNames(`section`, props.className)}>
      <div
        className={classNames(
          `section-image-mobile background-image-${
            props.imgBgPositionMobile ? props.imgBgPositionMobile : imgBgPosition
          }`
        )}
        style={stylesMobile}
      />

      <div className={classNames(`section-image-content background-${color}`)}>
        <AngleTop className="d-lg-none" color={color} />

        <div className={classNames(`section-image-content-inner`, props.innerClass)}>
          <Container>
            <div
              className={classNames(
                `section-image-desktop section-image-${imgPosition} section-image-${color} background-image-${imgBgPosition}`,
                sectionWithTitle
              )}
              style={stylesDesktop}
            />

            {props.title ? (
              <Row className={classNames(`section-image-content-title`, props.titleClass)}>
                <Col>{props.title}</Col>
              </Row>
            ) : null}

            <Row className={row}>
              <Col className={colClass}>{children}</Col>
            </Row>
          </Container>
        </div>

        <Angle color={color} />
      </div>
    </section>
  );
}

export function TriangleTop({color, ...props}) {
  return (
    <div className="section-triangle-top">
      <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
        <polygon points="0,500 0,0 250,30 500,0 500,500" className={classNames(`fill-polygon fill-${color}`)}></polygon>
        <defs>
          <linearGradient id="gradient-indigo-purple-right">
            <stop offset="0" stopColor="#6f00a2" />
            <stop offset="1" stopColor="#7d00b5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export function TriangleBottom({color, ...props}) {
  return (
    <div className="section-triangle-bottom">
      <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
        <polygon points="0,0 250,30 500,0" className={classNames(`fill-polygon fill-${color}`)}></polygon>
        <defs>
          <linearGradient id="gradient-indigo-purple-right">
            <stop offset="0" stopColor="#6f00a2" />
            <stop offset="1" stopColor="#7d00b5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export function SectionTriangle({
  id,
  color,
  type = null,
  offset = 'none',
  className = null,
  innerClass = null,
  imgUrl = null,
  imgPosition = null,
  bgImgPosition = 'center',
  children = null,
  ...props
}) {
  let backgroundImage = {
    backgroundImage: `url('${imgUrl}')`
  };

  const backgroundImageClasses = `background-image-${bgImgPosition} background-cover`;
  let row = `d-flex justify-content-${imgPosition === 'left' ? 'end' : 'start'}`;
  let sectionTriangleImageClass = `section-image-triangle-${imgPosition === 'left' ? 'left' : 'right'}`;

  return (
    <section
      id={id}
      className={classNames(
        `section section-triangle section-triangle-style-${type} section-triangle-offset-${offset} background-${color} ${
          imgUrl && !imgPosition ? backgroundImageClasses : ''
        } ${imgPosition ? sectionTriangleImageClass : ''}`,
        className
      )}
      style={imgUrl && !imgPosition ? backgroundImage : null}
    >
      {imgPosition ? (
        <>
          <div
            className={classNames(
              `section-triangle-image-mobile background-image-${
                props.imgBgPositionMobile ? props.imgBgPositionMobile : bgImgPosition
              }`
            )}
            style={backgroundImage}
          />

          <div className={classNames(`section-triangle-image-content background-${color}`)}>
            <div className={classNames(`section-triangle-image-content-inner`, props.innerClass)}>
              <div className="d-lg-none">
                <TriangleTop color={color} />
              </div>
              <Container>
                <div
                  className={classNames(
                    `section-triangle-image-desktop section-image-${imgPosition} section-image-${color} background-image-${bgImgPosition}`
                  )}
                  style={backgroundImage}
                />

                <Row className={props.rowClass ? props.rowClass : row}>
                  <Col className={props.colClass ? props.colClass : 'col-12 col-lg-7'}>{children}</Col>
                </Row>
              </Container>
            </div>
            {type === 'bottom' || type === 'both' ? <TriangleBottom color={color} /> : null}
          </div>
        </>
      ) : (
        <>
          {type === 'top' || type === 'both' ? <TriangleTop color={color} /> : null}
          <div className={classNames(`section-inner`, innerClass)}>{children}</div>
          {type === 'bottom' || type === 'both' ? <TriangleBottom color={color} /> : null}
        </>
      )}
    </section>
  );
}

export function SectionTriangleSpeakerCollapse({
  id,
  color,
  type = 'bottom',
  offset = 'none',
  className = null,
  innerClass = null,
  imgUrl = null,
  imgPosition = null,
  bgImgPosition = 'center',
  headlines = null,
  collapseHeader = null,
  collapseBgColor = 'purple',
  content = null,
  ...props
}) {
  let backgroundImage = {
    backgroundImage: `url('${imgUrl}')`
  };

  const backgroundImageClasses = `background-image-${bgImgPosition} background-cover`;
  let row = `d-flex justify-content-${imgPosition === 'left' ? 'end' : 'start'}`;
  let sectionTriangleImageClass = `section-image-triangle-${imgPosition === 'left' ? 'left' : 'right'}`;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((state) => !state);

  return (
    <section
      id={id}
      className={classNames(
        `section section-triangle section-triangle-style-${type} section-triangle-offset-${offset} background-${color} ${
          imgUrl && !imgPosition ? backgroundImageClasses : ''
        } ${imgPosition ? sectionTriangleImageClass : ''}`,
        className
      )}
      style={imgUrl && !imgPosition ? backgroundImage : null}
    >
      <>
        <div
          className={classNames(
            `section-triangle-image-mobile section-triangle-image-mobile-speaker background-image-${
              props.imgBgPositionMobile ? props.imgBgPositionMobile : bgImgPosition
            }`
          )}
          style={backgroundImage}
        />

        <div className={classNames(`section-triangle-image-content background-${color}`)}>
          <div className={classNames(`section-triangle-image-content-inner`, props.innerClass)}>
            <Container>
              <div
                className={classNames(
                  `section-triangle-image-desktop section-image-${imgPosition} section-image-${color} background-image-${bgImgPosition}`
                )}
                style={backgroundImage}
              />

              <Row className={props.rowClass ? props.rowClass : row}>
                <Col className={props.colClass ? props.colClass : 'col-12 col-lg-7'}>
                  <div className="text-center text-lg-left">{headlines}</div>
                  <div className="d-none d-lg-block pt-4">{content}</div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="d-none d-lg-block">
            {type === 'bottom' || type === 'both' ? <TriangleBottom color={color} /> : null}
          </div>
        </div>

        <div className="d-lg-none">
          <div
            className={classNames(
              `section-collapse-trigger d-flex align-items-center justify-content-between`,
              `background-${collapseBgColor}`,
              `text-white py-4`,
              props.titleStyle
            )}
            onClick={toggle}
          >
            <Container>
              <Row className="align-items-center">
                <Col xs="9">{collapseHeader}</Col>
                <Col xs="3">
                  <div className="section-collapse-header-icon d-flex align-items-center justify-content-end flex-column flex-lg-row text-h5 text-800">
                    <FontAwesomeIcon
                      className="section-collapse-icon mb-2 mb-lg-0 mr-lg-1"
                      icon={isOpen ? faChevronCircleUpRegular : faChevronCircleDownRegular}
                    />
                    <div className="section-collapse-header-icon-text text-small text-uppercase">
                      {isOpen ? (
                        <>Close</>
                      ) : (
                        <>
                          <span className="d-none d-lg-inline">Learn</span> More
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <Collapse isOpen={isOpen} className="d-lg-block">
            <Container>
              <Row>
                <Col>
                  <div className="section-triangle-image-content-inner">{content}</div>
                </Col>
              </Row>
            </Container>
          </Collapse>
        </div>
      </>
    </section>
  );
}

import React from 'react';
import {Form, Formik, Field} from 'formik';
import {Alert, Button, FormGroup, Input, Label} from 'reactstrap';

import {useUserDispatch, useUserState} from 'contexts/user';
import {submitForm} from 'utils/forms';

import CustomRadioField from '../CustomRadioField';

const {SEGMENTATION_FORM_ID} = process.env;

export default function ({variant = null, onComplete = () => {}, ...props}) {
  const userDispatch = useUserDispatch();
  const {uuid, mcFormReqUUID, registrationId} = useUserState();
  return (
    <>
      <p className="text-center mb-0">One more question:</p>
      <div className="optin-wrap">
        <Formik
          initialValues={{
            bucket: null
          }}
          onSubmit={(values, actions) => {
            if (!values || !values.bucket) {
              return onComplete();
            }
            return submitForm(
              {uuid, registrationId, request_uuid: mcFormReqUUID, bucket: values.bucket},
              SEGMENTATION_FORM_ID
            )
              .then(() => userDispatch({type: 'UPDATE_USER', payload: {bucket: values.bucket}}))
              .then(() => onComplete())
              .catch((e) => {
                actions.setStatus({error: e});
                actions.setSubmitting(false);
              });
          }}
          render={({values, handleSubmit, isSubmitting, isValid, handleReset, status, ...props}) => (
            <Form onSubmit={handleSubmit}>
              {status && status.error && <Alert color="danger">{status.error}</Alert>}
              <FormGroup className="text-left mb-4" tag="fieldset" value={values.bucket}>
                <h4 className="text-center mt-3 mb-4">
                  <strong>
                    What’s your biggest reason <br className="d-none d-md-block d-lg-none" />
                    for joining in this <br className="d-none d-lg-block" />
                    <em>Healthy Heart Masterclass</em>?
                  </strong>
                </h4>
                <FormGroup className="form-check-radio" check>
                  <Label check>
                    <Field name="bucket" value="prevent" component={CustomRadioField} />{' '}
                    {variant === 'ad' ? <>I want to stay healthy</> : <>I want to prevent heart disease</>}
                  </Label>
                </FormGroup>
                <FormGroup className="form-check-radio" check>
                  <Label check>
                    <Field name="bucket" value="optimize" component={CustomRadioField} /> I want to optimize my heart
                    for peak performance
                  </Label>
                </FormGroup>
                <FormGroup className="form-check-radio" check>
                  <Label check>
                    <Field name="bucket" value="reverse" component={CustomRadioField} />{' '}
                    {variant === 'ad' ? <>I have current heart concerns</> : <>I want to reverse heart disease</>}
                  </Label>
                </FormGroup>
              </FormGroup>

              <Button
                className="text-uppercase text-h4 text-condensed text-800"
                color="cta"
                type="submit"
                disabled={isSubmitting}
                block
              >
                {isSubmitting ? 'Loading...' : <>Complete Your Reservation</>}
              </Button>
            </Form>
          )}
        />
      </div>
    </>
  );
}

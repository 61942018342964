import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/pro-light-svg-icons/faLock';
import classNames from 'classnames';

import './SalesCta.scss';

export function SalesDisclaimer({iconColor = 'green', ...props}) {
  return (
    <div className="disclaimer-wrap">
      <div className="disclaimer-cards">
        <img
          className="img-fluid img-cc"
          src="https://cdn.foodrevolution.org/global/images/payments-accepted-20240424.svg"
          width={1800}
          height={175}
          alt="payment options graphic"
        />
      </div>
      <div className="disclaimer-copy text-small">
        <p className={props.noGuaranteeText ? 'mb-0' : 'mb-2'}>
          <FontAwesomeIcon className={classNames(`offer-box-security-icon text-${iconColor} mr-2`)} icon={faLock} /> For
          your security, all orders <br className="d-none d-sm-block d-md-none" />
          are processed on a secured server.
        </p>
        {props.noGuaranteeText ? null : (
          <p className="mb-0">
            Your purchase is protected by <br className="d-none d-sm-block d-md-none" />
            our 60-day money back guarantee.
          </p>
        )}
      </div>
    </div>
  );
}

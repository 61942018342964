import React, {useCallback, useState} from 'react';
import {Col, Container, Row, Button, Alert} from 'reactstrap';
import {Link} from 'react-scroll';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCloudArrowDown} from '@fortawesome/pro-regular-svg-icons';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section from 'components/Elements/Section';
import Video from 'components/Video';
import VideoButtons from 'components/Broadcasts/VideoButtons';
import ClosedCaptionModal from 'components/Broadcasts/ClosedCaptionModal';
import {ImageCard} from 'components/Elements/ImgBullet';
import CountdownTimer from 'components/Elements/CountdownTimer';
import CheckoutButton from 'components/CheckoutButton';
import CommentSection, {CommentSectionInline} from 'components/CommentSection';
import Modal from 'components/Modal';
import ModalExitBroadcasts from 'components/Modals/ModalExitBroadcasts';
import Footer from 'components/Elements/Footer';

// @ts-ignore
import {days} from 'data/pbcc-workshop.json';

export default function BroadcastIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = null,
  ctaNoTimer = false,
  variant = 'default',
  ...props
}) {
  const pageTitle = 'Plant-Based Coaching Workshop: A Live 3-Day Workshop';
  const pageDescription =
    'Learn the intricacies of nutrition, gain industry insights, and equip yourself to help people and the planet thrive.';
  const ogImage = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-workshop-broadcast-1200x1200.png';
  const shareUrl = 'https://certification.foodrevolution.org/workshop/';
  const facebookDescription =
    'Excited about plant-based living? Join our 3-day live workshop to transform your passion into a purposeful career. Learn from the best, champion health, and drive the food revolution. Register for free here.';
  const twitterDescription =
    'Join us for a transformative 3-day workshop on #PlantBasedCoaching with Ocean Robbins. Let’s shape a healthier world together. Register for free here.';
  const twitterImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-workshop-broadcast-1200x675.png';
  const pinterestDescription =
    'Discover the world of plant-based coaching with our immersive 3-day workshop. Empower yourself with knowledge, network with the experts, and set forth on a rewarding career path. Pin to inspire others to embark on this journey! Register for free here.';
  const pinterestImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-workshop-broadcast-1000x1500.png';

  const {
    phases: {
      default: defaultSchedule = {phase: ''},
      workshop: workshopSchedule = {phase: 'before'},
      broadcast: broadcastSchedule = {phase: 'before', day: 0, message: {color: '', text: ''}}
    }
  } = usePhaseState();

  const workshopPhase = workshopSchedule.phase;
  const broadcastPhase = broadcastSchedule.phase;

  const beforeWorkshop =
    workshopPhase === 'before' ||
    (workshopPhase === 'during' && broadcastPhase === 'before' && broadcastSchedule.day === 0);
  const afterWorkshop = workshopPhase === 'after';
  const workshopReplayAll = workshopSchedule.workshopReplayAll;
  const clarityCall = defaultSchedule.clarityCall;

  const workshopDate = new Date('2024-08-23T09:00:00-07:00').getTime();
  const commentsUrl = 'https://certification.foodrevolution.org/workshop/broadcasts/#pbccw2024';

  broadcastSchedule.day =
    broadcastSchedule.day && !isNaN(broadcastSchedule.day)
      ? Math.min(8, Math.max(0, parseInt(broadcastSchedule.day)))
      : 0;

  const productSku = 'pbcc';
  const productSlug = 'pbcc-2025-deposit';
  const productValue = 100;

  const TimerTomorrow = ({day = broadcastSchedule.day}) => {
    if (day + 1 >= days.length) return null;
    const {time: timeLater} = days[day + 1];
    const dateTomorrow = new Date(`${timeLater}`).getTime();
    return (
      <div className="next-broadcast mt-5">
        <h5 className="section-heading mb-2">The next workshop session is in:</h5>
        <CountdownTimer className="countdown-dark mb-3" time={dateTomorrow} />
      </div>
    );
  };

  const [zoomInstructionsIsOpen, zoomInstructionsSetIsOpen] = useState(false);
  const zoomInstructionsOnClick = useCallback(() => zoomInstructionsSetIsOpen(true), []);
  const zoomInstructionsToggle = useCallback(
    () => zoomInstructionsSetIsOpen((zoomInstructionsIsOpen) => !zoomInstructionsIsOpen),
    []
  ); //

  useBalanceText();

  return (
    <div className="page d-flex flex-column page-nosocial">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div className="page-content">
        <Header
          bgColor="black"
          lockupWhite
          logoUrlOverride="https://cdn.foodrevolution.org/pbcc/pbcc-logo-workshop-white.svg"
          logoAltOverride="plant-based coaching workshop logo"
          logoWidthOverride={300}
          logoHeightOverride={59}
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />

        <Section id="" color="purple" innerClass="text-center text-white py-4" angle="none">
          <Container>
            <Row>
              <Col>
                <h1 className="section-heading text-h2 mb-2">
                  Welcome to Food Revolution Network’s Plant-Based Coaching Workshop
                </h1>
                <h2 className="text-h3 mt-0 mb-3">
                  <i>The Recipe for Real and Lasting Change</i>
                </h2>
                <h4 className="mt-0 mb-0">
                  {workshopReplayAll ? (
                    <>All Sessions are on Replay Below</>
                  ) : (
                    <>Join the Revolution LIVE August 23–25, 2024</>
                  )}
                </h4>
                {beforeWorkshop && <CountdownTimer className="countdown-light mt-3" time={workshopDate} />}
              </Col>
            </Row>
          </Container>
        </Section>

        <Section
          id="broadcast-video"
          color="white"
          className="section-angle-first"
          innerClass="pt-0 pt-sm-4 pt-xl-5 my-auto"
        >
          {afterWorkshop || (workshopPhase === 'during' && workshopReplayAll) ? (
            <Container>
              <Row>
                <Col>
                  {workshopReplayAll && (
                    <>
                      {days.map((day, i) => {
                        return (
                          <div key={'workshop-day-' + day.number} className={i === '2' ? 'mb-0' : 'mb-5'}>
                            <h3 className="section-heading text-center mb-3">
                              Day {day.number}: {day.name}
                            </h3>
                            <p className="balance-text text-center mb-4">{day.description}</p>
                            <Video url={day.replayVideo} label="PBCC-W - Broadcasts - Video" />
                            <Row className="align-items-center mt-2">
                              <Col xs="12" className="mb-2">
                                <Button
                                  color="workshop-download"
                                  size="sm"
                                  href={day.downloads.transcript.url}
                                  target="_blank"
                                  block
                                >
                                  <FontAwesomeIcon icon={faCloudArrowDown} /> {day.downloads.transcript.buttonText}
                                </Button>
                              </Col>
                              <Col xs="12" className="mb-2">
                                <Button
                                  color="workshop-download"
                                  size="sm"
                                  href={day.downloads.handoutOne.url}
                                  target="_blank"
                                  block
                                >
                                  <FontAwesomeIcon icon={faCloudArrowDown} /> {day.downloads.handoutOne.buttonText}
                                </Button>
                              </Col>
                              <Col xs="12" className="">
                                <Button
                                  color="workshop-download"
                                  size="sm"
                                  href={day.downloads.handoutTwo.url}
                                  target="_blank"
                                  block
                                >
                                  <FontAwesomeIcon icon={faCloudArrowDown} /> {day.downloads.handoutTwo.buttonText}
                                </Button>
                              </Col>
                            </Row>
                            <div
                              className={classNames(
                                `video-buttons`,
                                `text-center`,
                                `d-flex`,
                                `align-items-center`,
                                `justify-content-center`,
                                `mt-2`
                              )}
                            >
                              <Link
                                className="btn btn-medium-gray text-16 text-800 text-white px-4 py-2 mr-2"
                                activeClass="active"
                                spy={true}
                                offset={0}
                                to="comments"
                                smooth={true}
                                href="#"
                              >
                                Comment Now
                              </Link>
                              <ClosedCaptionModal />
                            </div>
                          </div>
                        );
                      })}
                      <div className="px-4 px-lg-5">
                        <p className="text-center mt-4 mb-2">
                          <b>Capture every takeaway with this fillable Workshop Workbook:</b>
                        </p>
                        <p className="text-center">
                          <Button
                            href="https://cdn.foodrevolution.org/members/pdf/pbcc/Plant-Based%20Coaching%20Workshop%20-%20Workbook.pdf"
                            target="_blank"
                            color="cta"
                            size="sm"
                          >
                            Workbook Download Here
                          </Button>
                        </p>
                        <p className="text-center balance-text mt-5">
                          The Plant-Based Coaching Workshop has concluded, but we’re excited to announce that we are
                          enrolling for the Plant-Based Coaching Certification through January 8, 2025, or until we have
                          reached capacity. To learn more about the program and apply, visit{' '}
                          <a href="/join/">https://certification.foodrevolution.org/join/</a>.
                        </p>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          ) : (
            <Container fluid>
              {broadcastPhase === 'after' ? (
                <div className="text-center">
                  <h3 className="section-heading">Click this button to access the replay:</h3>
                  <Button color="cta" size="lg" href="/workshop/replays/">
                    Access the Replay
                  </Button>
                  <TimerTomorrow />
                </div>
              ) : (
                <Row className="px-xl-5">
                  <Col xs="12" lg="8" className="px-0 px-sm-3">
                    {broadcastSchedule.message && broadcastSchedule.message.text ? (
                      <Alert color={broadcastSchedule.message.color ? broadcastSchedule.message.color : 'primary'}>
                        {broadcastSchedule.message.text}
                      </Alert>
                    ) : null}
                    <Video
                      url={
                        broadcastSchedule.youtubeUrl
                          ? broadcastSchedule.youtubeUrl
                          : 'https://www.youtube.com/watch?v=xUtY11Zuf60'
                      }
                      label="PBCC-W - Broadcasts - Video"
                      playing
                    />
                    <VideoButtons className="mt-2" />
                    <div className="px-4 px-lg-5">
                      <p className="text-center mt-4 mb-2">
                        <b>Capture every takeaway with this fillable Workshop Workbook:</b>
                      </p>
                      <p className="text-center mb-4">
                        <Button
                          href="https://cdn.foodrevolution.org/members/pdf/pbcc/Plant-Based%20Coaching%20Workshop%20-%20Workbook.pdf"
                          target="_blank"
                          color="cta"
                          size="sm"
                        >
                          Workbook Download Here
                        </Button>
                      </p>
                      <p className="balance-text">
                        <b>How It Works:</b> Each day of the workshop, we will broadcast a new session starting at 12 pm
                        ET / 9 am PT / 4 pm GMT. After each day’s screening ends, the workshop will be available on{' '}
                        <a href="/workshop/replays" target="_blank">
                          replay
                        </a>{' '}
                        for a day. Each session will run for about three hours.
                      </p>
                      <p className="balance-text">
                        Join Food Revolution Network’s Ocean Robbins, Tracy Garrigan, CHHC, Nichole Dandrea-Russert,
                        RDN, and special guest experts for a live, three-day workshop where you’ll learn about
                        plant-based nutrition, coaching, and how to share this information — so you can help people heal
                        their lives, and turn your passion for plant-based eating into a deeply rewarding vocation.
                      </p>
                      <h6 className="text-center mt-4 mb-3">Click Here to Add These Events to Your Calendar</h6>
                      <Row className="text-center align-items-center">
                        <Col lg="4" className="mb-4 mb-lg-0">
                          <p className="mb-2">
                            <b>Friday, August 23rd</b>
                          </p>
                          <Button
                            title="Add to Calendar"
                            className="addeventatc"
                            data-id="kn22228380"
                            href="https://www.addevent.com/event/kn22228380"
                            target="_blank"
                            color="secondary"
                          >
                            Add to Calendar
                          </Button>
                        </Col>
                        <Col lg="4" className="mb-4 mb-lg-0">
                          <p className="mb-2">
                            <b>Saturday, August 24th</b>
                          </p>
                          <Button
                            title="Add to Calendar"
                            className="addeventatc"
                            data-id="IR22228381"
                            href="https://www.addevent.com/event/IR22228381"
                            target="_blank"
                            color="secondary"
                          >
                            Add to Calendar
                          </Button>
                        </Col>
                        <Col lg="4">
                          <p className="mb-2">
                            <b>Sunday, August 25th</b>
                          </p>
                          <Button
                            title="Add to Calendar"
                            className="addeventatc"
                            data-id="eZ22228382"
                            href="https://www.addevent.com/event/eZ22228382"
                            target="_blank"
                            color="secondary"
                          >
                            Add to Calendar
                          </Button>
                        </Col>
                      </Row>
                      <h4>How to Engage & Ask Questions</h4>
                      <div className="text-left">
                        <p>
                          It is not required to ask questions during the workshop, if you’d rather just sit back and
                          listen, you can do so on this page. Or, there are two ways you can ask your questions for our
                          expert speakers to answer during the workshop:
                        </p>
                        <ol className="list-ol">
                          <li>
                            Post your questions, thoughts, comments, and reactions in the comment stream on this page
                            below or at the right sidebar. <b>Note:</b> You’ll need a Facebook account to post questions
                            on this page.
                          </li>
                          <li>
                            Click through the Zoom link. This option will open up the Zoom Webinar, where you’ll join as
                            an audience member and can ask questions directly in the Q&A box. If you’re new to Zoom, you
                            can either{' '}
                            <a href="https://zoom.us/download" target="_blank">
                              download the app for free here
                            </a>
                            , or you{' '}
                            <a className="link-purple" role="button" onClick={zoomInstructionsOnClick}>
                              can join from your browser
                            </a>
                            . The Zoom links are below:
                            <ul>
                              <li>
                                Day 1, August 23:{' '}
                                <a href="https://us02web.zoom.us/j/84532100657" target="_blank">
                                  Click here if you would like to join.
                                </a>
                              </li>
                              <li>
                                Day 2, August 24:{' '}
                                <a href="https://us02web.zoom.us/j/85265081381" target="_blank">
                                  Click here if you would like to join.
                                </a>
                              </li>
                              <li>
                                Day 3, August 25:{' '}
                                <a href="https://us02web.zoom.us/j/89787451153" target="_blank">
                                  Click here if you would like to join.
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" lg="4" className="d-none d-lg-flex">
                    <div className="box-shadow-black-25 rounded p-3 w-100">
                      <CommentSectionInline
                        id="comments"
                        numPosts="10"
                        url={commentsUrl}
                        isBroadcastPage
                        shareUrl={shareUrl}
                        shareImg={ogImage}
                        facebookDescription={facebookDescription}
                        twitterDescription={twitterDescription}
                        twitterImg={twitterImg}
                        pinterestDescription={pinterestDescription}
                        pinterestImage={pinterestImg}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          )}
        </Section>

        {!afterWorkshop || workshopReplayAll ? (
          <Section id="" color="light-gray">
            <Container>
              <Row className="align-items-stretch">
                <ImageCard
                  img="https://cdn.foodrevolution.org/pbcc/images/1409236261.jpg"
                  colLg="4"
                  contentClassOverride="d-flex flex-column flex-grow-1 text-left"
                >
                  <h5 className="section-heading text-center mb-3">Day One: Plant-Based Nutrition</h5>
                  <p className="text-16">We’ll share:</p>
                  <ul className="text-16">
                    <li>Key advantages and potential pitfalls of a plant-based diet</li>
                    <li>How to maximize the benefits and tips to maximize positive outcomes</li>
                    <li>The FRN approach to embracing a whole-food plant-based lifestyle</li>
                  </ul>
                </ImageCard>
                <ImageCard
                  img="https://cdn.foodrevolution.org/pbcc/images/1411238326.jpg"
                  colLg="4"
                  contentClassOverride="d-flex flex-column flex-grow-1 text-left"
                >
                  <h5 className="section-heading text-center mb-3">Day Two: Coaching</h5>
                  <p className="text-16">Discover:</p>
                  <ul className="text-16">
                    <li>How structure paves the way for client success</li>
                    <li>FRN’s essential coaching skills and unique techniques</li>
                    <li>The magic of coaching in action with an engaging live demonstration</li>
                  </ul>
                </ImageCard>
                <ImageCard
                  img="https://cdn.foodrevolution.org/pbcc/images/1198696330.jpg"
                  colLg="4"
                  contentClassOverride="d-flex flex-column flex-grow-1 text-left"
                >
                  <h5 className="section-heading text-center mb-3">Day Three: Impact</h5>
                  <ul className="text-16">
                    <li>Learn more about the growing plant-based living movement</li>
                    <li>Become a catalyst for healing</li>
                    <li>
                      Unleash your potential by exploring dynamic coaching pathways—whether you start your own business,
                      find fulfilling employment, or both
                    </li>
                    <li>Learn powerful grassroots strategies to make a lasting impact on your family and community.</li>
                  </ul>
                </ImageCard>
              </Row>
            </Container>
          </Section>
        ) : null}

        <Section id="pbccw-cta" color="purple" className="text-center text-white">
          <Container>
            <Row>
              <Col>
                <h4 className="section-heading">Bring the Food Revolution to the World as a Plant-Based Coach</h4>
                <p className="balance-text">
                  Are you looking for a dream job where you get to do deeply rewarding work? Or would you like to find
                  out how to make a difference as a plant-based advocate?
                </p>
                <p className="balance-text mb-0">
                  Turn your love of plant-based foods into a thriving vocation where you get to help people and help the
                  planet.
                </p>
                <div>
                  <img
                    className="my-4"
                    src="https://cdn.foodrevolution.org/pbcc/pbcc-logo-white.svg"
                    alt="plant-based coaching certification logo"
                    style={{maxHeight: '100px'}}
                  />
                </div>
                {!afterWorkshop && (
                  <>
                    <h5 className="text-400 mt-0 mb-2">
                      Apply before 11:59 pm Eastern on August 26th to be eligible for:
                    </h5>
                    <p className="mb-4">
                      Special Tuition Discount (Save up to $1,585)
                      <br />
                      Unlimited Membership in WHOLE Life Club (Value $497/year)
                      <br />
                      Ocean Robbins’ Food Revolution Mastermind (Value $3,000)
                      <br />
                      2020–2025 Food Revolution Summit Empowerment Packages (Value $1,182)
                    </p>
                  </>
                )}
                <p>
                  <Button href="/join/" color="cta" target="_blank" size="lg">
                    Learn More
                  </Button>
                </p>
                <h3 className="mt-5 mb-3">Ready to Apply Now? Click the button below.</h3>
                <p>
                  <CheckoutButton sku={productSku} price={productValue} slug={productSlug} target="_blank">
                    Save My Spot
                  </CheckoutButton>
                </p>
                <p className="text-small">
                  This will take you to a page to submit your $100 refundable deposit, and then you can apply. Your
                  deposit counts towards your total tuition. If your application is accepted, your space will be held
                  for two weeks, so you can submit payment. The application takes about 10 minutes to complete.
                </p>
                {clarityCall && (
                  <>
                    <h3 className="mt-5 mb-3">Have questions? Speak with a program advisor.</h3>
                    <p>
                      <Button href="/clarity-calls/" color="cta" target="_blank" size="lg">
                        Book A Clarity Call
                      </Button>
                    </p>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Section>

        <CommentSection
          id="comments"
          numPosts="10"
          url={commentsUrl}
          isBroadcastPage
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />
      </div>

      <Modal isOpen={zoomInstructionsIsOpen} toggle={zoomInstructionsToggle} size="lg">
        <Container className="px-4 px-sm-5 pt-0 pb-5" fluid>
          <Row className="d-flex justify-content-center">
            <Col>
              <h5 className="section-heading text-center">How to join meetings and webinars with the web client:</h5>
              <p>Users can join Zoom meetings or webinars from their web browser and bypass downloading Zoom.</p>
              <ol>
                <li>Locate the webinar or meeting link</li>
                <li>Click the join link to join the meeting</li>
                <li>If a pop-up window prompts you to open or install the Zoom desktop client, click Cancel</li>
                <li>At the bottom of the page, click the Join from your Browser link</li>
                <li>Click Join</li>
              </ol>
              <p>Note: If you don't see this option, make sure to enable Join From Browser.</p>
              <p>
                Note: If prompted, sign in to your Zoom account. If you do not have a Zoom account, create a new account
                here{' '}
                <a href="https://zoom.us/" target="_blank">
                  https://zoom.us/
                </a>
                . You will be prompted to enter your name and the meeting password if it was not included in the join
                link.
              </p>
            </Col>
          </Row>
        </Container>
      </Modal>

      {!afterWorkshop && <ModalExitBroadcasts />}

      <div className="footer-content mt-auto">
        <Footer />
      </div>
      <script type="text/javascript" src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js" async defer></script>
    </div>
  );
}

import React, {Children} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCircle} from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import './IconList.scss';

export default function IconList({iconColor = 'green', children, ...props}) {
  return <ul className={classNames(`icon-list icon-list-${iconColor} fa-ul`, props.className)}>{children}</ul>;
}

export function IconListItem({icon = faCheck, children, ...props}) {
  return (
    <li className={classNames(`icon-list-item d-flex align-items-center`, props.className)}>
      {props.number ? (
        <div className="icon-list-number-wrap fa-layers fa-li">
          <FontAwesomeIcon className="icon-list-number-circle" icon={faCircle} />
          <div className="icon-list-number-value fa-layers-text text-800">{props.number}</div>
        </div>
      ) : (
        <FontAwesomeIcon className="fa-li" icon={icon} />
      )}

      <div className="icon-list-item-content">{children}</div>
    </li>
  );
}

export function ListIcon({iconColor = 'green', icon = faCheck, number = false, children, className = null}) {
  const arrayChildren = Children.toArray(children);
  return (
    <ul
      className={classNames(
        `icon-list`,
        `icon-list-${iconColor}`,
        `icon-list-${number ? 'number' : 'default'}`,
        `fa-ul`,
        className
      )}
    >
      {Children.map(arrayChildren, (child, i) => {
        return (
          <li key={i} className={classNames(`icon-list-item d-flex align-items-center`)}>
            {number ? (
              <div className="icon-list-number-wrap fa-layers fa-li">
                <FontAwesomeIcon className="icon-list-number-circle" icon={faCircle} />
                <div className="icon-list-number-value fa-layers-text text-800">{i + 1}</div>
              </div>
            ) : (
              <FontAwesomeIcon className="fa-li" icon={icon} />
            )}
            <div className="icon-list-item-content">{child}</div>
          </li>
        );
      })}
    </ul>
  );
}

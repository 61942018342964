import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row, Collapse} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faChevronCircleUp} from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import {faq} from 'data/faq.json';
import './FaqList.scss';

const remarkSubSuper = require('remark-sub-super');

export default function FaqList({
  faqlist = faq,
  clarityCall = false,
  phaseIteration,
  masterclassOverride = false,
  borderColor = 'black-15',
  slice = 0,
  ...props
}) {
  const [activeCards, setActiveCards] = React.useState([]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );

  const insider = phaseIteration === 'insiderPromo';
  const lateApp = phaseIteration === 'lateApp';

  return faqlist.slice(slice).map((faq, index) => {
    if (
      (insider && faq.id === 'appDeadlineLate') ||
      (insider && faq.id === 'appDeadline') ||
      (insider && faq.id === 'directory') ||
      (insider && faq.id === 'refundPolicy') ||
      (!insider && faq.id === 'refundPolicyInsider') ||
      (lateApp && faq.id === 'appDeadline') ||
      (lateApp && faq.id === 'appDeadlineInsider') ||
      (!lateApp && !insider && faq.id === 'appDeadlineInsider') ||
      (!lateApp && !insider && faq.id === 'appDeadlineLate') ||
      (clarityCall && faq.id === 'questionsAnsweredClarityCall') ||
      (!clarityCall && faq.id === 'questionsAnswered')
    ) {
      return null;
    }
    return (
      <div className={classNames(`faq-item faq-item-border-${borderColor}`)} key={index}>
        <div className="faq-item-header d-flex align-items-center justify-content-between" onClick={onClick(index)}>
          <div className="faq-item-header-text">
            <ReactMarkdown className="faq-item-header-title" source={faq.question || 'faq question'} />
          </div>
          <div className="faq-item-header-icon d-flex align-items-center justify-content-end flex-column flex-lg-row">
            <FontAwesomeIcon
              className="faq-item-icon mb-1 mb-lg-0 mr-lg-1"
              icon={activeCards.includes(index) ? faChevronCircleUp : faChevronCircleDown}
            />
            <div className="faq-item-header-icon-text text-small text-uppercase">
              {activeCards.includes(index) ? (
                <>Close</>
              ) : (
                <>
                  <span className="d-none d-lg-inline">Learn</span> More
                </>
              )}
            </div>
          </div>
        </div>
        <Collapse isOpen={activeCards.includes(index)}>
          <div className="faq-item-content">
            <ReactMarkdown
              source={faq.answer || 'faq answer'}
              plugins={[remarkSubSuper]}
              renderers={{
                sub: 'sub',
                sup: 'sup'
              }}
            />
          </div>
        </Collapse>
      </div>
    );
  });
}

import React, {useCallback, useState} from 'react';
import {Container, Col, Row, Button} from 'reactstrap';
import cc from 'static/svg/closed-caption.svg';
import closedCaptionExample from 'static/closed-caption-example.jpg';
import './ClosedCaptionModal.scss';
import Modal from 'components/Modal';

export default function ClosedCaptionModal({...props}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
  return (
    <>
      <Button className="btn-cc" color="img" onClick={onClick}>
        <img src={cc} />
      </Button>

      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <Container className="px-4 px-sm-5 pt-0 pb-5" fluid>
          <Row className="d-flex justify-content-center">
            <Col>
              <h3 className="section-heading text-center">About Closed Captions</h3>
              <p className="mb-3">
                You can enable/disable captions during the live broadcast by clicking the captioning button located in
                the bottom right of the player.
              </p>
              <img
                src={closedCaptionExample}
                width="1200"
                height="675"
                alt="Youtube video with graphics for closed caption enabling"
              />
            </Col>
          </Row>
        </Container>
      </Modal>
    </>
  );
}

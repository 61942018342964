import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {FacebookProvider, Like} from 'react-facebook';
const {SITE_URL} = process.env;

export default function FacebookLikeButton({url = `${SITE_URL}masterclass/`, ...props}) {
  return (
    <FacebookProvider appId="731824260184014">
      <Like href={url} layout="button_count" action="like" size="large" showFaces="false" share="false" />
    </FacebookProvider>
  );
}

import React from 'react';
import queryString from 'query-string';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const {DEFAULT_BUCKET} = process.env;
const {BUCKETS} = process.env;

const boundBucket = (b) => {
  if (typeof BUCKETS === 'undefined') return b;
  return typeof b === 'undefined' || !BUCKETS.includes(b) ? DEFAULT_BUCKET : b;
};
const initialState = (() => {
  if (typeof window === 'undefined') return {};
  const qs = queryString.parse(window.location.search);
  let {email, firstName, b, registrationId, uuid} = qs || {};
  const lsUser = (localStorage && JSON.parse(localStorage.getItem('user'))) || {};
  // Don't allow local store or qs override to exceed bucket bounds
  lsUser.bucket = boundBucket(lsUser.bucket);
  b = BUCKETS.includes(b) ? b : undefined;

  return {
    bucket: DEFAULT_BUCKET,
    events: [],
    ...lsUser,
    ...(typeof b !== 'undefined' && {bucket: b}),
    ...(email && {email}),
    ...(firstName && {firstName}),
    ...(registrationId && {registrationId}),
    ...(uuid && {uuid})
  };
})();

function UserReducer(state, action) {
  switch (action.type) {
    case 'SET_USER': {
      let {bucket} = action.payload;
      bucket = boundBucket(bucket);
      return {...action.payload, ...(bucket && {bucket}), events: []};
    }
    case 'ADD_EVENT': {
      return !state.events.includes(action.payload) ? {...state, events: [...state.events, action.payload]} : state;
    }
    case 'UPDATE_USER': {
      return {...state, ...action.payload};
    }
    default: {
      throw new Error('Unhandled action type: ${action.type}');
    }
  }
}

function UserProvider({value, children}) {
  const [state, dispatch] = React.useReducer(UserReducer, initialState);
  React.useEffect(() => {
    typeof window !== 'undefined' && localStorage.setItem('user', JSON.stringify(state));
  }, [state]);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export {UserProvider, useUserState, useUserDispatch};

import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {FacebookProvider, Comments} from 'react-facebook';
const {SITE_URL} = process.env;

export default function FacebookCommentFeed({url = `${SITE_URL}`, numPosts = 5, ...props}) {
  return (
    <div className="facebook-comments-feed">
      <Container className={props.containerClass} fluid>
        <Row>
          <Col xs="12">
            <FacebookProvider appId="1685199371775570">
              <Comments href={url} width="100%" numPosts={numPosts} orderBy="reverse_time" key={props.commentsKey} />
            </FacebookProvider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

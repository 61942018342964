import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik, Field} from 'formik';
import {DateTime} from 'luxon';
import {Alert, Button, FormGroup, Label} from 'reactstrap';
import * as Yup from 'yup';

import {useUserDispatch} from 'contexts/user';
import {submitForm} from 'utils/forms';
import CustomInputField from '../CustomInputField';
import CustomCheckboxField from '../CustomCheckboxField';
import CustomSelectField from '../CustomSelectField';
import {usePhaseState} from 'funnel-schedule/phase-context';
import classNames from 'classnames';
import track, {identify} from 'utils/track';

const {SF_API_URL} = process.env;
const {MASTERCLASS_OPTIN_FORM_ID} = process.env;
const {SITE_ID} = process.env;

let USER_TIMEZONE = null;

try {
  USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
} catch (err) {
  console.error(err);
}

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  sms_number: Yup.string().when('bulk_sms', {
    is: true,
    then: (s) =>
      s
        .matches(/(^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$)/, 'Please enter a valid US telephone number')
        .required('Required')
  }),
  webinar_time: Yup.number().required('Required'),
  website: Yup.string().test('is-empty', 'Leave this empty.', (value) => !value)
});

export default function ContactInfoForm({
  id,
  contactFormHeader,
  entry,
  linkColor,
  noHeadline = false,
  optinButtonText = null,
  onComplete = () => {},
  submitButtonClassName
}) {
  const userDispatch = useUserDispatch();
  const {
    phases: {masterclass: schedule = {}}
  } = usePhaseState();
  const [timeSlots, setTimeSlots] = useState({loaded: false, data: []});
  useEffect(() => {
    const {webinar_id} = schedule;
    axios.get(`${SF_API_URL}/webinar/${webinar_id}/schedule`).then(({data}) => setTimeSlots({loaded: true, data}));
  }, [schedule]);
  return (
    <>
      {contactFormHeader ? (
        contactFormHeader
      ) : (
        <>
          <p className="text-center mb-2">
            This 80-minute Masterclass is absolutely FREE and plays in your local time.
          </p>
          <p className="text-center mb-4">
            <b>Enter your name and email below to sign up.</b>
          </p>
        </>
      )}

      <div className="optin-wrap mb-3">
        <Formik
          initialValues={{
            email: '',
            firstName: '',
            bulk_sms: false,
            sms_number: '',
            webinar_time: '',
            timezone: USER_TIMEZONE,
            report: entry
          }}
          validationSchema={SignupSchema}
          onSubmit={({sms_number, ...values}, actions) => {
            return submitForm({sms_number: sms_number.replace(/\D/g, ''), ...values}, MASTERCLASS_OPTIN_FORM_ID)
              .then(({data}) => {
                identify(data.proxy_data.user.uuid, {
                  uuid: data.proxy_data.user.uuid,
                  firstName: values.firstName,
                  email: values.email,
                  ...(sms_number ? {phone: sms_number} : {})
                });
                return userDispatch({
                  type: 'SET_USER',
                  payload: {
                    firstName: values.firstName,
                    email: values.email,
                    registrationId: data.proxy_data.registrationId,
                    mcFormReqUUID: data.proxy_data.mc_form_uuid,
                    uuid: data.proxy_data.user.uuid
                  }
                });
              })
              .then(() =>
                track('User Registered', {
                  property: SITE_ID,
                  entryPoint: entry,
                  label: `${SITE_ID} - ${entry}`
                })
              )
              .then(() => onComplete())
              .catch((e) => {
                actions.setStatus({error: e});
                actions.setSubmitting(false);
              });
          }}
          render={({values, handleSubmit, isSubmitting, isValid, status}) => (
            <Form onSubmit={handleSubmit}>
              {status && status.error && <Alert color="danger">{status.error}</Alert>}
              <FormGroup>
                <Label for="firstName" hidden>
                  First Name
                </Label>
                <Field
                  name="firstName"
                  type={'text'}
                  component={CustomInputField}
                  placeholder="What is your first name?"
                  bsSize="lg"
                />
              </FormGroup>
              <FormGroup>
                <Label for="email" hidden>
                  Email
                </Label>
                <Field
                  name="email"
                  type={'email'}
                  component={CustomInputField}
                  placeholder="What is your best email?"
                  bsSize="lg"
                />
              </FormGroup>

              <FormGroup>
                <Field
                  name="bulk_sms"
                  id={`${id}-bulk_sms`}
                  component={CustomCheckboxField}
                  label={
                    <>
                      Send me text message reminders and updates
                      <br />
                      <span className="text-small">
                        *Optional & not required to join. Text message updates are for US mobile numbers only.
                      </span>
                    </>
                  }
                  type="checkbox"
                  bsSize="lg"
                />
              </FormGroup>

              {values.bulk_sms ? (
                <FormGroup className="sms-input-group">
                  <Label for="tel" hidden>
                    Phone Number
                  </Label>
                  <Field
                    type="tel"
                    placeholder="What is your mobile number?"
                    name="sms_number"
                    component={CustomInputField}
                    mask="+1 (999) 999-9999"
                    bsSize="lg"
                  />
                </FormGroup>
              ) : null}

              <FormGroup>
                <p className="text-center">
                  <strong>Choose your preferred session below.</strong>
                </p>
                <Field
                  type="select"
                  name="webinar_time"
                  component={CustomSelectField}
                  disabled={!timeSlots.loaded || !timeSlots.data.length}
                  bsSize="lg"
                >
                  {timeSlots.loaded ? (
                    timeSlots.data.length ? (
                      [
                        <option value="" key={0} disabled selected>
                          Select a time...
                        </option>
                      ].concat(
                        timeSlots.data.sort().map((v, i) => (
                          <option value={v} key={v}>
                            {DateTime.fromSeconds(v).toLocaleString({
                              month: 'short',
                              day: '2-digit',
                              hour: 'numeric',
                              minute: '2-digit',
                              timeZoneName: 'short',
                              weekday: 'short'
                            })}
                          </option>
                        ))
                      )
                    ) : (
                      <option value="">No time slots currently available.</option>
                    )
                  ) : (
                    <option value="">Loading...</option>
                  )}
                </Field>
              </FormGroup>

              <FormGroup className="d-none">
                <Label for="Website" hidden>
                  Should be left blank
                </Label>
                <Field name="website" type={'text'} component={CustomInputField} placeholder="" bsSize="lg" />
              </FormGroup>

              <Button
                className={classNames(
                  'text-uppercase text-h4 text-condensed text-800 w-80 important',
                  submitButtonClassName && submitButtonClassName
                )}
                color="cta"
                type="submit"
                disabled={isSubmitting || !isValid}
                block
              >
                {isSubmitting ? 'Loading...' : optinButtonText ? optinButtonText : 'Continue'}
              </Button>
            </Form>
          )}
        />
      </div>
      <p className="text-small text-left">
        By entering your information here, you are agreeing to receive messages from Food Revolution Network. Your email
        address will NEVER be shared or sold. You are always free to easily unsubscribe at any time. See our{' '}
        <a
          className={classNames(`text-underline link-${linkColor}`)}
          href="https://foodrevolution.org/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>{' '}
        for more info.
      </p>
      <p className="text-disclaimer text-small text-left">
        By checking the "Send me text message reminders and updates" box you agree to receive important updates,
        reminders, and promotional messages about events and products from Food Revolution Network (FRN). Message
        frequency varies. You can stop receiving messages at any time by texting STOP to 67692, for help text HELP to
        67692. Message and data rates may apply. By opting in for text messages, you authorize FRN to deliver marketing
        messages using an automatic telephone dialing system. SMS opt-in is not a requirement for purchasing any
        property, goods, or services. By leaving the "Send me text message reminders and updates" box unchecked you will
        not be opted in for SMS messages at this time. See our{' '}
        <a
          className={classNames(`text-underline link-${linkColor}`)}
          href="https://foodrevolution.org/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          className={classNames(`text-underline link-${linkColor}`)}
          href="https://foodrevolution.org/terms-and-conditions/"
          target="_blank"
        >
          Terms
        </a>{' '}
        for more info.
      </p>
    </>
  );
}

import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik, Field} from 'formik';
import {Alert, Button, FormGroup, Label} from 'reactstrap';
import * as Yup from 'yup';

import {useUserDispatch} from 'contexts/user';
import CustomInputField from '../CustomInputField';
import {usePhaseState, usePhaseDispatch} from 'funnel-schedule/phase-context';

const {SF_API_URL} = process.env;
const {SITE_ID} = process.env;

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required')
});

export default function RegistrantInfoForm() {
  const userDispatch = useUserDispatch();
  const {
    phases: {masterclass: schedule = {}}
  } = usePhaseState();
  const phaseDispatch = usePhaseDispatch();
  const [timeSlots, setTimeSlots] = useState({loaded: false, data: []});
  useEffect(() => {
    const {webinar_id} = schedule;
    axios.get(`${SF_API_URL}/webinar/${webinar_id}/schedule`).then(({data}) => setTimeSlots({loaded: true, data}));
  }, [schedule]);
  const initialValues = {
    email: ''
  };
  return (
    <>
      <div className="optin-wrap mb-3">
        <Formik
          initialValues={initialValues}
          validationSchema={SignupSchema}
          onSubmit={(values, actions) => {
            const {email} = values;
            return axios
              .get(`${SF_API_URL}/site/${SITE_ID}/state/`, {
                params: {
                  email
                }
              })
              .then(({data}) => {
                const {firedEvents = []} = data.webinar || {};
                if (!data.webinar) {
                  //actions.resetForm(initialValues);
                  actions.setStatus({
                    error: (
                      <>
                        No registration was found with that email address. Please try another email address or
                        re-register{' '}
                        <a className="link-danger" href="/masterclass/">
                          here
                        </a>
                        .
                      </>
                    )
                  });
                  actions.setSubmitting(false);
                  return;
                }
                phaseDispatch({
                  type: 'SET_PHASE',
                  payload: data
                });
                userDispatch({type: 'SET_USER', payload: {email: values.email}});
                firedEvents.map((e) => userDispatch({type: 'ADD_EVENT', payload: e}));
              })
              .catch((e) => {
                actions.setStatus({error: e});
                actions.setSubmitting(false);
              });
          }}
          render={({values, handleSubmit, isSubmitting, isValid, status}) => (
            <Form onSubmit={handleSubmit}>
              <p className="text-center">
                <strong>Enter your email to retrieve your registration.</strong>
              </p>
              {status && status.error && <Alert color="danger">{status.error}</Alert>}

              <FormGroup>
                <Label for="email" hidden>
                  Email
                </Label>
                <Field
                  name="email"
                  type={'email'}
                  component={CustomInputField}
                  placeholder="Enter the email you registered with"
                  bsSize="lg"
                />
              </FormGroup>

              <Button
                className="text-uppercase text-h4 text-condensed text-800"
                color="cta"
                type="submit"
                disabled={isSubmitting || !isValid}
                block
              >
                {isSubmitting ? 'Loading...' : 'Submit'}
              </Button>
            </Form>
          )}
        />
      </div>
    </>
  );
}

import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {Link, animateScroll as scroll} from 'react-scroll';
import classNames from 'classnames';
import ClosedCaptionModal from 'components/Broadcasts/ClosedCaptionModal';

export default function VideoButtons({className = null, replay = false, ...props}) {
  return (
    <div
      className={classNames(`video-buttons text-center d-flex align-items-center justify-content-center`, className)}
    >
      <div className={classNames(`mr-2`, !replay && `d-lg-none`)}>
        <Link
          className="btn btn-medium-gray text-white px-4"
          activeClass="active"
          spy={true}
          offset={0}
          to="comments"
          smooth={true}
          href="#"
        >
          Comment Now
        </Link>
      </div>
      <ClosedCaptionModal />
    </div>
  );
}

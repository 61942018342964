import React, {useCallback, useState} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import FacebookCommentFeed from 'components/Social/FacebookCommentFeed';
import FacebookLikeButton from 'components/Social/FacebookLikeButton';
import ShareButtons from 'components/Social/ShareButtons';
import classNames from 'classnames';
import './CommentSection.scss';
import Section from 'components/Elements/Section';

export default function CommentSection({
  shareUrl,
  shareImg,
  facebookDescription,
  twitterDescription,
  twitterHashtags = undefined,
  pinterestDescription,
  pinterestImage,
  fbLikeUrl = 'https://www.facebook.com/foodrevolutionnetwork',
  ...props
}) {
  const [commentsKey, setCommentsKey] = useState(0);
  const refreshComments = useCallback(() => setCommentsKey((commentsKey) => commentsKey + 1), []);

  return (
    <Section
      id={classNames(props.id)}
      color="white"
      className={classNames(`section-angle-last`, `section-comments`, props.className)}
      angle="none"
    >
      <Container>
        <Row className="d-flex align-items-center mb-4 mb-lg-5">
          <Col xs="12" lg="7" className="mb-4 mb-lg-0">
            <h3 className="section-heading">Share Your Comments & Reactions</h3>
            <p className="mb-0">
              To see the most recent comments, click the blue “GET THE LATEST COMMENTS” button below.
            </p>
          </Col>
          <Col xs="12" lg="5">
            <a className="comment-bubble d-flex flex-column justify-content-center" href="#comments">
              <div className="comment-bubble-inner my-auto">Post your comments and share your reactions.</div>
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="comment-box">
              <Row className="d-flex align-items-end text-center mb-4 mb-lg-5">
                <Col xs="12" lg="7" className="mb-4 mb-lg-0">
                  <Row className="d-flex align-items-stretch justify-content-center text-center">
                    <div className="wrap-like mr-md-3 mb-3 mb-md-0 d-flex flex-column">
                      <p className="comment-box-social-title text-uppercase text-700 mb-0">Like FRN</p>
                      <div className="my-auto">
                        <FacebookLikeButton url="https://facebook.com/foodrevolutionnetwork/" />
                      </div>
                    </div>
                    <div className="wrap-share">
                      <p className="comment-box-social-title text-uppercase text-700 mb-0">Share With Friends</p>
                      <ShareButtons
                        instagramButton="false"
                        shareUrl={shareUrl}
                        shareImg={shareImg}
                        facebookDescription={facebookDescription}
                        twitterDescription={twitterDescription}
                        twitterHashtags={twitterHashtags}
                        pinterestDescription={pinterestDescription}
                        pinterestImage={pinterestImage}
                      />
                    </div>
                  </Row>
                </Col>
                <Col xs="12" lg="5">
                  <Button className="btn btn-lg btn-refresh text-uppercase" onClick={refreshComments}>
                    Get The Latest Comments
                  </Button>
                </Col>
              </Row>
              <Row id="comment">
                <Col xs="12">
                  <FacebookCommentFeed {...props} key={commentsKey} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export function CommentSectionInline({
  shareUrl,
  shareImg,
  facebookDescription,
  twitterDescription,
  twitterHashtags = undefined,
  pinterestDescription,
  pinterestImage,
  fbLikeUrl = 'https://www.facebook.com/foodrevolutionnetwork',
  noSocial = true,
  ...props
}) {
  const [commentsKey, setCommentsKey] = useState(0);
  const refreshComments = useCallback(() => setCommentsKey((commentsKey) => commentsKey + 1), []);

  return (
    <div className={classNames(`comment-box-inline`)}>
      <div className="comment-box-inline-tools text-center mb-3">
        <Button className="btn btn-refresh text-uppercase" onClick={refreshComments}>
          Get The Latest Comments
        </Button>
        {!noSocial && (
          <>
            <div className="wrap-like my-3">
              <p className="comment-box-social-title text-uppercase text-700 mb-0">Like FRN</p>
              <FacebookLikeButton />
            </div>
            <div className="wrap-share mb-3">
              <p className="comment-box-social-title text-uppercase text-700 mb-0">Share With Friends</p>
              <ShareButtons
                alignment="justify-content-center"
                shareUrl={shareUrl}
                shareImg={shareImg}
                facebookDescription={facebookDescription}
                twitterDescription={twitterDescription}
                twitterHashtags={twitterHashtags}
                pinterestDescription={pinterestDescription}
                pinterestImage={pinterestImage}
              />
            </div>
          </>
        )}
      </div>

      <FacebookCommentFeed containerClass="p-0" {...props} key={commentsKey} />
    </div>
  );
}

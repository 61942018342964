import React, {Component, useCallback, useState} from 'react';
import {Container, Row, Col, Collapse, Button} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faChevronCircleUp} from '@fortawesome/pro-light-svg-icons';
import LazyLoad from 'react-lazyload';
import {modules, pillars, speakers} from 'data/module.json';
import IconList, {IconListItem} from 'components/Elements/IconList';
import Modal from 'components/Modal';
import classNames from 'classnames';
import './ModuleAccordion.scss';
import Section from 'components/Elements/Section';
import useBalanceText from 'utils/useBalanceText';

const remarkSubSuper = require('remark-sub-super');

export default function ModuleAccordion({modulelist = modules, ...props}) {
  const [activeCards, setActiveCards] = React.useState([0]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  return modulelist.map((module, index) => {
    return (
      <div className="module-item" key={index}>
        <div className="module-item-header d-flex align-items-center justify-content-between" onClick={onClick(index)}>
          <div className="module-item-header-text">
            <Row className="bonus-heading d-flex align-items-center justify-content-start m-0">
              <Col xs="12" md="3" lg="2" className="text-center mb-3 mb-md-0 pl-lg-0">
                <img className="module-img" src={module.thumb} />
              </Col>
              <Col xs="12" md="9" lg="10">
                <div className="module-item-count">{module.module}</div>
                <div className="module-item-title">{module.name}</div>
              </Col>
            </Row>
          </div>
          <div className="module-item-header-icon d-flex align-items-center justify-content-end flex-column flex-lg-row">
            <FontAwesomeIcon
              className="module-item-icon mb-1 mb-lg-0 mr-lg-1"
              icon={activeCards.includes(index) ? faChevronCircleUp : faChevronCircleDown}
            />
            <div className="module-item-header-icon-text text-small text-uppercase">
              {activeCards.includes(index) ? (
                <>Close</>
              ) : (
                <>
                  <span className="d-none d-lg-inline">Learn</span> More
                </>
              )}
            </div>
          </div>
        </div>
        <Collapse isOpen={activeCards.includes(index)}>
          <div className="module-item-content">
            <ReactMarkdown
              className="module-item-description"
              source={module.description || 'description'}
              plugins={[remarkSubSuper]}
              renderers={{
                sub: 'sub',
                sup: 'sup'
              }}
            />
            <IconList>
              {modulelist[index].list.map((list) => (
                <IconListItem>
                  <ReactMarkdown className="module-item-lessons" source={list || 'list-item'} />
                </IconListItem>
              ))}
            </IconList>
            <Row className="align-items-center justify-content-center justify-content-sm-start text-center">
              <Col xs="12" md="auto" className="mb-4 mb-md-0">
                <img
                  className="rounded-circle img-border-shadow"
                  src={module.expertImage}
                  style={{maxHeight: '120px'}}
                />
              </Col>
              <Col xs="12" md="auto" className="text-md-left">
                <h5 className="section-heading mb-2">
                  Expert Interview With <span className="text-400">{module.expertName}</span>
                </h5>
                <p className="mb-0">
                  <em>{module.expertInterview}</em>
                </p>
              </Col>
            </Row>
          </div>
        </Collapse>
      </div>
    );
  });
}

export function PillarModal({image, name, bio, ...props}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
  return (
    <>
      <Button color="link-pillar" onClick={onClick} className={props.className}>
        Read Bio
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <Container className="px-sm-4 pt-0 pb-5" fluid>
          <Row className="d-flex justify-content-center">
            <Col xs="10" lg="4" className="mb-4 mb-lg-0">
              <LazyLoad height={300} offset={100}>
                <img className="box-shadow-image rounded" src={image} />
              </LazyLoad>
            </Col>
            <Col xs="12" lg="8">
              <div className="speaker-details">
                <h3 className="speaker-name section-heading mb-2">{name}</h3>
                <ReactMarkdown className="speaker-description" source={bio} />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>
    </>
  );
}

export function PillarModules({list = pillars, ...props}) {
  useBalanceText();
  return list.map((pillar, i) => {
    return (
      <Section id={classNames(`pillar-${i + 1}`)} color={i % 2 === 0 ? 'white' : 'light-gray'} className="pillar-item">
        <Container>
          <Row>
            <Col>
              <h3 className="pillar-item-title section-heading mb-2">{pillar.pillar}</h3>
              <h2 className="pillar-item-theme mt-0 mb-3">{pillar.pillarTheme}</h2>
              <ReactMarkdown
                className="pillar-item-description"
                source={pillar.pillarDescription ? pillar.pillarDescription : 'Pillar description'}
              />
              <h5>Lead Faculty:</h5>
              <div className="pillar-item-faculty-wrap mt-5">
                {pillar.leadFaculty.map((faculty, i) => {
                  return (
                    <>
                      {speakers
                        .filter((speaker) => speaker.id === faculty.id)
                        .map((speaker) => {
                          return (
                            <div id={classNames(`pillar-item-faculty-${i + 1}`)} className="pillar-item-faculty mb-5">
                              <Row className="align-items-center justify-content-center justify-content-lg-start">
                                <Col
                                  xs="10"
                                  md="8"
                                  lg="4"
                                  xl="3"
                                  className={classNames(
                                    `text-center mb-4 mb-lg-0 ${i % 2 === 0 ? 'order-lg-2' : 'order-lg-1'}`
                                  )}
                                >
                                  <img
                                    className="pillar-item-faculty-image box-shadow-image rounded-circle"
                                    src={speaker.image}
                                  />
                                </Col>
                                <Col
                                  lg="8"
                                  xl="9"
                                  className={classNames(`${i % 2 === 0 ? 'order-lg-1' : 'order-lg-2'}`)}
                                >
                                  <h5 className="text-center text-lg-left mt-0 mb-2">{speaker.name}</h5>
                                  <p className="text-center text-lg-left balance-text">
                                    <b>{speaker.tagline}</b>
                                  </p>
                                  {speaker.bio ? (
                                    <ReactMarkdown
                                      className="pillar-item-faculty-bio"
                                      source={speaker.bio ? speaker.bio : 'TODO: faculty bio'}
                                    />
                                  ) : null}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                    </>
                  );
                })}
              </div>

              {pillar.modules.map((module, i) => {
                return (
                  <div className={classNames(`pillar-item-module pillar-item-module-${i + 1}`)}>
                    <h5 className="section-heading mb-2">{module.module}</h5>
                    <h4 className="mt-0">{module.theme}</h4>
                    <ReactMarkdown
                      className="pillar-item-module-description"
                      source={module.description ? module.description : 'TODO: module description'}
                    />
                    <div className="module-faculty-list">
                      {module.faculty.map((faculty, i) => {
                        return (
                          <div className="module-faculty-item mb-4">
                            <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                              <div className="module-faculty-image-wrap text-center">
                                {speakers
                                  .filter((speaker) => speaker.id === faculty.speakerID)
                                  .map((speaker) => {
                                    return (
                                      <img
                                        className="module-faculty-image box-shadow-image rounded"
                                        src={speaker.image}
                                      />
                                    );
                                  })}
                              </div>
                              <div className="module-faculty-lesson flex-grow-1">
                                <p className="text-800 mb-1">{faculty.lesson}</p>
                                {speakers
                                  .filter((speaker) => speaker.id === faculty.speakerID)
                                  .map((speaker) => {
                                    return (
                                      <>
                                        {speaker.name ? <p className="mb-1">{speaker.name}</p> : null}
                                        {speaker.bio ? (
                                          <PillarModal name={speaker.name} image={speaker.image} bio={speaker.bio} />
                                        ) : null}
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      </Section>
    );
  });
}

export function PillarModulesCondensed({list = pillars, variant = 'default', ...props}) {
  useBalanceText();
  return list.map((pillar, i) => {
    return (
      <Section id={classNames(`pillar-${i + 1}`)} color={i % 2 === 0 ? 'white' : 'light-gray'} className="pillar-item">
        <Container>
          <Row>
            <Col>
              {pillar.pillarImage && pillar.pillarImage.alt ? (
                <div className="d-flex align-items-center mb-3">
                  <div style={{maxWidth: '150px'}}>
                    <img
                      className="rounded box-shadow-black-25"
                      src={pillar.pillarImage.url}
                      alt={pillar.pillarImage.alt}
                    />
                  </div>
                  <div className="pl-4">
                    <h3 className="pillar-item-title section-heading mb-2">{pillar.pillar}</h3>
                    <h2 className="pillar-item-theme m-0">{pillar.pillarTheme}</h2>
                  </div>
                </div>
              ) : (
                <>
                  <h3 className="pillar-item-title section-heading mb-2">{pillar.pillar}</h3>
                  <h2 className="pillar-item-theme mt-0 mb-3">{pillar.pillarTheme}</h2>
                </>
              )}

              {pillar.pillarImage && pillar.pillarImage.url ? (
                <div className="">
                  <div className="text-center pillar-image-align-right">
                    <img
                      className="rounded box-shadow-black-25"
                      src={pillar.pillarImage.url}
                      alt={pillar.pillarImage.alt}
                    />
                  </div>
                  <ReactMarkdown
                    className="pillar-item-description"
                    source={
                      variant === 'ad' && pillar.pillarDescriptionAd
                        ? pillar.pillarDescriptionAd
                        : pillar.pillarDescription
                    }
                  />
                </div>
              ) : (
                <ReactMarkdown
                  className="pillar-item-description"
                  source={
                    variant === 'ad' && pillar.pillarDescriptionAd
                      ? pillar.pillarDescriptionAd
                      : pillar.pillarDescription
                  }
                />
              )}

              {pillar.leadFaculty && (
                <>
                  <h5>Lead Faculty:</h5>
                  <div className="pillar-item-faculty-wrap mt-5">
                    {pillar.leadFaculty.map((faculty, i) => {
                      return (
                        <>
                          {speakers
                            .filter((speaker) => speaker.id === faculty.id)
                            .map((speaker) => {
                              return (
                                <div
                                  id={classNames(`pillar-item-faculty-${i + 1}`)}
                                  className="pillar-item-faculty mb-5"
                                >
                                  <Row className="align-items-center justify-content-center justify-content-lg-start">
                                    <Col
                                      xs="10"
                                      md="8"
                                      lg="4"
                                      xl="3"
                                      className={classNames(
                                        `text-center mb-4 mb-lg-0 ${i % 2 == 0 ? 'order-lg-1' : 'order-lg-2'}`
                                      )}
                                    >
                                      <img
                                        className="pillar-item-faculty-image box-shadow-image rounded-circle"
                                        src={speaker.image}
                                      />
                                    </Col>
                                    <Col
                                      lg="8"
                                      xl="9"
                                      className={classNames(`${i % 2 == 0 ? 'order-lg-2' : 'order-lg-1'}`)}
                                    >
                                      <h5 className="text-center text-lg-left mt-0 mb-2">{speaker.name}</h5>
                                      <p className="text-center text-lg-left balance-text">
                                        <b>{speaker.tagline}</b>
                                      </p>
                                      {speaker.bio ? (
                                        <ReactMarkdown
                                          className="pillar-item-faculty-bio"
                                          source={speaker.bio ? speaker.bio : 'TODO: faculty bio'}
                                        />
                                      ) : null}
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                        </>
                      );
                    })}
                  </div>
                </>
              )}

              {pillar.guestFaculty && (
                <div className="guest-faculty-wrap">
                  <h5>Guest Faculty:</h5>
                  <Row className="guest-faculty-list align-items-stretch justify-content-center mt-5">
                    {pillar.guestFaculty.map((faculty, i) => {
                      return (
                        <>
                          {speakers
                            .filter((speaker) => speaker.id === faculty.id)
                            .map((speaker) => {
                              return (
                                <Col xs="6" lg="3" className="d-flex flex-column text-center mb-4">
                                  <img className="box-shadow-image rounded mb-3" src={speaker.image} />
                                  <h5
                                    className={classNames(
                                      `guest-speaker-name guest-speaker-name-${speaker.id} text-16 text-800 mt-0 mb-1`
                                    )}
                                  >
                                    {speaker.name}
                                  </h5>
                                  {speaker.bio ? (
                                    <div className="mt-auto">
                                      <PillarModal
                                        name={speaker.name}
                                        image={speaker.image}
                                        bio={speaker.bio}
                                        className="text-small"
                                      />
                                    </div>
                                  ) : null}
                                </Col>
                              );
                            })}
                        </>
                      );
                    })}
                  </Row>
                </div>
              )}

              <div className="mt-5">
                {pillar.modules.map((module, i) => {
                  return (
                    <div
                      className={classNames(
                        `pillar-item-module-condensed`,
                        `pillar-item-module-${i + 1}`,
                        `background-white`,
                        `box-shadow-black-50`,
                        `rounded`,
                        `p-4`,
                        `p-lg-5`,
                        `mb-4`
                      )}
                    >
                      {module.icon ? (
                        <div className="d-flex align-items-center mb-4">
                          <div className="text-green" style={{width: '75px'}}>
                            <FontAwesomeIcon
                              className="w-100 h-auto"
                              icon={require('@fortawesome/pro-regular-svg-icons')[module.icon]}
                              style={{maxHeight: '75px'}}
                            />
                          </div>
                          <div className="pl-4">
                            <h5 className="section-heading mb-2">{module.module}</h5>
                            <h4 className="m-0">{module.theme}</h4>
                          </div>
                        </div>
                      ) : (
                        <>
                          <h5 className="section-heading mb-2">{module.module}</h5>
                          <h4 className="mt-0">{module.theme}</h4>
                        </>
                      )}
                      <ReactMarkdown
                        className="pillar-item-module-description"
                        source={variant === 'ad' && module.descriptionAd ? module.descriptionAd : module.description}
                      />
                      <hr className="border-black-50 my-4" />

                      {module.faculty.map((faculty, i) => {
                        return (
                          <div className="module-faculty-item">
                            <p>{faculty.lesson}</p>
                          </div>
                        );
                      })}

                      {module.leadFaculty && (
                        <>
                          <hr className="border-black-50 my-4" />
                          <h5>Lead Faculty:</h5>
                          {module.leadFaculty.map((faculty, i) => {
                            return (
                              <>
                                {speakers
                                  .filter((speaker) => speaker.id === faculty.id)
                                  .map((speaker) => {
                                    return (
                                      <div
                                        id={classNames(`pillar-item-faculty-${i + 1}`)}
                                        className="pillar-item-faculty"
                                      >
                                        <Row className="align-items-center justify-content-center justify-content-lg-start">
                                          <Col
                                            xs="10"
                                            md="8"
                                            lg="4"
                                            xl="3"
                                            className={classNames(
                                              `text-center mb-4 mb-lg-0 ${i % 2 == 0 ? 'order-lg-1' : 'order-lg-2'}`
                                            )}
                                          >
                                            <img
                                              className="pillar-item-faculty-image box-shadow-image rounded-circle"
                                              src={speaker.image}
                                            />
                                          </Col>
                                          <Col
                                            lg="8"
                                            xl="9"
                                            className={classNames(`${i % 2 == 0 ? 'order-lg-2' : 'order-lg-1'}`)}
                                          >
                                            <h5 className="text-center text-lg-left mt-0 mb-2">{speaker.name}</h5>
                                            <p className="text-center text-lg-left balance-text mb-2">
                                              <b>{speaker.tagline}</b>
                                            </p>
                                            {speaker.bio ? (
                                              <div className="">
                                                <PillarModal
                                                  name={speaker.name}
                                                  image={speaker.image}
                                                  bio={speaker.bio}
                                                  className="text-small"
                                                />
                                              </div>
                                            ) : null}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })}
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>

              {pillar.practice && (
                <>
                  {pillar.practice.image && pillar.practice.image.url ? (
                    <div className="background-white box-shadow-black-50 rounded mt-4 d-flex flex-column flex-lg-row">
                      <div
                        className="pillarPracticeImage"
                        style={{backgroundImage: `url('${pillar.practice.image.url}')`}}
                      />
                      <div className="pillarPracticeContent p-4 p-lg-5">
                        <h4 className="section-heading">{pillar.practice.title}</h4>
                        <ReactMarkdown
                          source={
                            variant === 'ad' && pillar.practice.descriptionAd
                              ? pillar.practice.descriptionAd
                              : pillar.practice.description
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="background-white box-shadow-black-50 rounded p-4 p-lg-5 mt-4">
                      <h4 className="section-heading">{pillar.practice.title}</h4>
                      <ReactMarkdown
                        source={
                          variant === 'ad' && pillar.practice.descriptionAd
                            ? pillar.practice.descriptionAd
                            : pillar.practice.description
                        }
                      />
                    </div>
                  )}
                </>
              )}

              {pillar.impact && (
                <div className="background-white box-shadow-black-50 rounded p-4 p-lg-5 mt-4">
                  <h4 className="section-heading">{pillar.impact.title}</h4>
                  <ReactMarkdown
                    source={
                      variant === 'ad' && pillar.impact.descriptionAd
                        ? pillar.impact.descriptionAd
                        : pillar.impact.description
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Section>
    );
  });
}

import React from 'react';
import {navigate} from '@reach/router';
import {ModalBody, Button} from 'reactstrap';

import Modal from 'components/Modal';
import OptInForm from 'components/OptInForm';
import OptInFormMasterclass from 'components/OptInFormMasterclass';
import ModalContext, {useModal} from 'contexts/modal';
import classNames from 'classnames';

export default function OptinModal({
  entry = 'default',
  btnText = null,
  contactFormHeader = null,
  disableNavigate = false,
  variant = null,
  masterclass = false,
  ...props
}) {
  const navigateUrl = masterclass ? '/masterclass/watch/' : '/workshop/broadcasts/';
  const modalProps = useModal();
  const [navigateOnClose, setNavigateOnClose] = React.useState(false);
  const onComplete = React.useCallback(() => {
    modalProps.toggle();
    !disableNavigate && navigate(navigateUrl, {state: {fromEntry: entry}});
  }, []);
  const onStepComplete = React.useCallback((step) => step === 1 && setNavigateOnClose(true), []);
  const toggle = React.useCallback(() => {
    if (navigateOnClose && !disableNavigate) navigate(navigateUrl, {state: {fromEntry: entry}});
    modalProps.toggle();
  }, [navigateOnClose]);
  return (
    <Modal {...props} isOpen={modalProps.isOpen} toggle={toggle} size="lg">
      <ModalBody className="px-4 pt-0 pb-4">
        {masterclass ? (
          <OptInFormMasterclass
            onComplete={onComplete}
            id="modalOptInForm"
            entry={entry}
            variant={variant}
            contactFormHeader={contactFormHeader}
            btnText={btnText}
          />
        ) : (
          <OptInForm
            onComplete={onComplete}
            id="modalOptInForm"
            entry={entry}
            variant={variant}
            contactFormHeader={contactFormHeader}
            btnText={btnText}
          />
        )}
      </ModalBody>
    </Modal>
  );
}

export function OptinModalTrigger({children, ...props}) {
  const className = classNames('btn-size-350 text-uppercase text-white text-condensed text-800', props.className);
  const modalProps = useModal();
  return (
    <Button color="cta" size="lg" {...props} className={className} onClick={modalProps.toggle}>
      {children ? children : 'Sign Up Now!'}
    </Button>
  );
}

import React from 'react';
import {Button, Col, Row} from 'reactstrap';

import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent.js';

export default function ModalExitBroadcasts() {
  const {isOpen, toggle} = useExitIntent(30000);
  return (
    <Modal className="modal-exit-intent modal-exit-intent-screening" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h4 className="section-heading mb-4">Before you go…</h4>
          <p>The Plant-Based Coaching Workshop is still going strong!</p>
          <p>Learn how to turn passion for healthy eating into a vocation that changes the world.</p>
          <p>Head back to finish watching now.</p>
          <Button className="btn-exit text-uppercase text-white text-h6" color="cta" onClick={toggle} block>
            Return to the Workshop
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div
            className="exit-modal-image"
            style={{backgroundImage: `url('https://cdn.foodrevolution.org/pbcc/images/1479411838-exit.jpg')`}}
          />
        </Col>
      </Row>
    </Modal>
  );
}

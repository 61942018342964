import React from 'react';
import {Col, Row} from 'reactstrap';

import Modal from 'components/Modal';
import {OptinModalTrigger} from 'components/OptinModal';
import useExitIntent from 'utils/useExitIntent.js';

export default function ModalExitLanding() {
  const {isOpen, toggle} = useExitIntent(30000);
  return (
    <Modal className="modal-exit-intent modal-exit-intent-landing" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h4 className="section-heading mb-4">Did you know that Plant-Based Coaches are more in-demand than ever?</h4>
          <p>
            The Plant-Based Coaching Workshop will show you how to turn your passion for healthy eating into a thriving
            contribution to a healthier world.
          </p>
          <p>Join us live August 23–25th!</p>
          <OptinModalTrigger className="mt-4" block>
            Register Now
          </OptinModalTrigger>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div
            className="exit-modal-image"
            style={{backgroundImage: `url('https://cdn.foodrevolution.org/pbcc/images/1356200030-exit.jpg')`}}
          />
        </Col>
      </Row>
    </Modal>
  );
}

import React, {Fragment} from 'react';
import {Col, Container, Row, Button} from 'reactstrap';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Section from 'components/Elements/Section';
import {ListIcon} from 'components/Elements/IconList';
import Footer from 'components/Elements/Footer';
import Header from 'components/Elements/Header';

//@ts-ignore
import {clarityCallAdvisors} from 'data/pbcc-content.json';
//@ts-ignore
import {speakers} from 'data/module.json';

export default function ClarityCallsIndex() {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();

  const clarityCall = defaultSchedule.clarityCall;

  const pageTitle =
    'Change the way the world eats with Food Revolution Network’s Plant-Based Coaching Certification Program';
  const pageDescription = 'Get everything you need to transform your clients’ lives and your life.';
  const ogImage = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1200x1200-20221004.png';
  const shareUrl = 'https://certification.foodrevolution.org/join/';
  const facebookDescription =
    'Do what you love and make a difference with the world’s only plant-based health coaching program to combine nutrition, coaching skills, and business building. Learn more here:';
  const twitterDescription =
    'Do what you love and make a difference with the world’s only plant-based health coaching program to combine nutrition, coaching skills, and business building. Learn more here:';
  const twitterImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1200x675-20221004.png';
  const pinterestDescription =
    'Do what you love and make a difference with the world’s only plant-based health coaching program to combine nutrition, coaching skills, and business building. Learn more here:';
  const pinterestImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1000x1500-20221004.png';

  useBalanceText();
  return (
    <div className="page d-flex flex-column page-nosocial">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />
      <div className="page-content">
        <Header
          bgColor="black"
          lockupWhite
          logoUrlOverride="https://cdn.foodrevolution.org/pbcc/pbcc-logo-white.svg"
          logoAltOverride="plant-based coaching logo"
          logoWidthOverride={300}
          logoHeightOverride={59}
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />

        <Section id="pbcc-clarity-header" color="white" className="text-center" angle="none">
          <Container>
            <Row>
              <Col>
                <div className="background-light-gray rounded box-shadow-black-50 p-4 p-lg-5">
                  <h1 className="section-heading text-h2 mb-3">Book Your Free Clarity Call</h1>
                  <h4 className="mt-0">
                    The Plant-Based Coaching Certification is a launch pad to bring the food revolution to the world!
                  </h4>
                  <div className="d-flex justify-content-center text-left">
                    <ListIcon className="m-0">
                      <>Reinvent, Revitalize, or Complement Your Career</>
                      <>Gain Deep Knowledge of Plant-Based Nutrition</>
                      <>Learn How To Help People Implement Lasting Changes</>
                      <>Do Meaningful Work That Helps People & The Planet</>
                      <>Work From Anywhere In The World</>
                      <>Create Your Own Personal Health Transformation</>
                      <>Join the Growing Movement of Leaders in the Food Revolution</>
                    </ListIcon>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="pbcc-clarity-book" color="purple" className="section-angle-last" angle="top">
          <Container>
            <Row>
              <Col>
                <div className="text-center text-white">
                  <h3 className="section-heading mb-3">Is becoming a Plant-Based Coach right for you?</h3>
                  <h3 className="mt-0 mb-5">Have questions about FRN’s Plant-Based Coaching Certification program?</h3>
                  <h5 className="mt-0">Speak to one of our program advisors:</h5>
                  <p className="balance-text mb-4">
                    <i>
                      <b>If you have already enrolled</b> please email us at{' '}
                      <a className="link-white text-underline" href="mailto:certification@foodrevolution.org">
                        certification@foodrevolution.org
                      </a>{' '}
                      to speak with your Coach Mentor, Makini Duewa, NBC-HWC
                    </i>
                  </p>
                </div>

                <Row className="d-flex align-items-stretch justify-content-center">
                  {clarityCallAdvisors.map((advisor, i) => {
                    return (
                      <Fragment key={advisor + i}>
                        {speakers
                          .filter((speaker) => speaker.id === advisor.id)
                          .map((speaker) => {
                            return (
                              <Col
                                key={speaker.id}
                                md="6"
                                xl="4"
                                className="d-flex flex-column flex-grow-1 mb-4 mb-md-0"
                              >
                                <div className="background-white rounded box-shadow-black-50 d-flex flex-column text-center flex-grow-1">
                                  <div
                                    className="clarity-call-image"
                                    style={{backgroundImage: `url('${speaker.image}')`}}
                                  />
                                  <div className="clarity-call-detail p-3 flex-grow-1 d-flex flex-column">
                                    <h5 className="section-heading mb-1">{speaker.name}</h5>
                                    {speaker.tagline && <p className="balance-text mb-0">{speaker.tagline}</p>}
                                    <p className="balance-text text-16 mt-3 mb-4">{speaker.bio}</p>
                                    <p className="text-center mt-auto mb-0">
                                      <Button
                                        className="text-uppercase"
                                        color="cta mt-4"
                                        href={speaker.clarityCallLink.url}
                                        target="_blank"
                                        block
                                        disabled={speaker.clarityCallLink.disabled}
                                      >
                                        Book My Clarity Call with {speaker.shortName}
                                      </Button>
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                      </Fragment>
                    );
                  })}
                </Row>
                <p className="text-center text-white mt-5">The program begins on January 14, 2025.</p>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>
      <div className="footer-content mt-auto">
        <Footer />
      </div>
    </div>
  );
}

import React from 'react';
import classNames from 'classnames';

export function ListReferences({children, ...props}) {
  return <ul className="list-references list-group text-small mb-0">{children}</ul>;
}

export function ListReferenceItem({number, text, ...props}) {
  return (
    <li className="list-group-item reference-item d-flex align-items-center p-3">
      <div className="pr-3">[{number}]</div>
      <div>{text}</div>
    </li>
  );
}

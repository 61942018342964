import React, {useCallback, useRef, useState} from 'react';
import {faClosedCaptioning, faPlay, faStop, faVolume, faVolumeMute} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'reactstrap';
import Video from 'components/Video';
import './Webinar.scss';
import {faCircleNotch} from '@fortawesome/pro-light-svg-icons';

export default function WebinarVideo({
  events = [],
  url,
  noun = 'Webinar',
  pos = 0,
  controls = true,
  onEnded = () => {},
  onEventFired = () => {},
  onProgress = () => {},
  label,
  defaultTextTrack,
  ...props
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [pauseTime, setPauseTime] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const player = useRef(null);
  const play = useCallback(() => {
    //setIsPlaying(true);
    player.current.getInternalPlayer().play();
  }, [player]);

  const toggleMute = useCallback(() => setIsMuted((s) => !s), []);
  const pausePlay = useCallback(
    () =>
      setIsPlaying((s) => {
        if (!s) {
          if (pauseTime > 0) {
            player.current.seekTo(
              player.current.getCurrentTime() + (new Date().getTime() - pauseTime) / 1000,
              'seconds'
            );
          }
          setIsLoading(true);
        }
        if (!s) play();
        return false; //If playing we want to pause, if paused we want for isPlaying to be updated by onPlay
      }),
    [player, pauseTime]
  );
  const showControls = useCallback(() => setIsHovered(true), []);
  const hideControls = useCallback(() => setIsHovered(false), []);
  const preventBubble = useCallback(
    (f) => (e) => {
      e.stopPropagation();
      return f(e);
    },
    []
  );
  const onPlay = useCallback(() => {
    setIsPlaying(true), props.onPlay && props.onPlay.apply(this, arguments);
  }, [props.onPlay]);

  const onPause = useCallback(() => {
    setPauseTime(new Date().getTime());
    props.onPause && props.onPause.apply(this, arguments);
  }, [player, props.onPause]);

  const onReady = useCallback(() => {
    if (pos) {
      player.current.seekTo(pos, 'seconds');
    }
    setIsPlaying(true);
    setIsLoading(true);
    props.onReady && props.onReady.apply(this, arguments);
  }, [player, props.onReady]);

  const onError = useCallback(
    (e) => {
      setIsPlaying(player.current.player.isPlaying);
      props.onError && props.onError.apply(this, arguments);
    },
    [player, props.onError]
  );
  const toggleCC = useCallback(() => {
    const vPlayer = player.current.getInternalPlayer();
    vPlayer.getTextTracks().then((tracks) => {
      tracks.length &&
        (!tracks.find((t) => t.mode === 'showing')
          ? vPlayer.enableTextTrack(tracks[0].language)
          : vPlayer.disableTextTrack());
    });
  }, [player]);
  const _onProgress = useCallback(
    (e) => {
      isLoading && setIsLoading(false);
      onProgress.apply(this, arguments);
    },
    [isLoading]
  );
  return (
    <div
      className="webinar-wrapper"
      style={{position: 'relative'}}
      onMouseEnter={showControls}
      onMouseLeave={hideControls}
    >
      <Video
        url={url}
        playsinline
        onReady={onReady}
        onError={onError}
        ref={player}
        muted={isMuted}
        playing={isPlaying}
        onPlay={onPlay}
        onPause={onPause}
        onProgress={_onProgress}
        onEnded={onEnded}
        onEventFired={onEventFired}
        events={events}
        config={{
          vimeo: {
            playerOptions: {controls}
          }
        }}
        volume={1}
        label={label}
        controls={false}
        defaultTextTrack={defaultTextTrack}
      />
      {!isPlaying ? (
        <div
          className="webinar-overlay background-black-50 text-white d-flex align-items-center justify-content-center"
          onClick={preventBubble(play)}
          style={{cursor: 'pointer'}}
        >
          Click here to join your {noun}
        </div>
      ) : null}
      {isPlaying && isLoading ? (
        <div className="webinar-overlay background-black-50 text-white d-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={faCircleNotch} size="2x" spin />
        </div>
      ) : null}
      <div
        style={{
          position: 'absolute',
          display: isHovered ? 'flex' : 'none',
          width: '100%',
          bottom: 0,
          left: 0,
          alignItems: 'center',
          padding: '20px'
        }}
      >
        <Button onClick={preventBubble(pausePlay)} color="dark" className="px-4 py-2">
          <FontAwesomeIcon icon={!isPlaying ? faPlay : faStop} />
        </Button>
        <div className="ml-auto">
          <Button onClick={preventBubble(toggleMute)} color="dark">
            <FontAwesomeIcon icon={!isMuted ? faVolume : faVolumeMute} />
          </Button>
          {/*<Button onClick={preventBubble(toggleCC)} color="dark" className="ml-3">
            <FontAwesomeIcon icon={faClosedCaptioning} />
          </Button>*/}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import {Head} from 'react-static';
const {SITE_URL} = process.env;

export default function PageMeta({
  url = `https://certification.foodrevolution.org/join/`,
  ogImage = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-open-graph-1200x1200-20221004.png',
  title = 'Change the way the world eats with Food Revolution Network’s Plant-Based Coaching Certification Program',
  description = 'Get everything you need to transform your clients’ lives and your life.'
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@afoodrevolution" />
      <meta property="twitter:image" content={ogImage} />
      <meta property="fb:app_id" content="1685199371775570" />
      <meta property="og:site_name" content="Plant Based Coaching Certification" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
    </Head>
  );
}

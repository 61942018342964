import React, {useState} from 'react';
import {navigate} from '@reach/router';
import classNames from 'classnames';
import ContactInfoForm from './components/ContactInfoForm';
import SegmentationForm from './components/SegmentationForm';
import {ModalBody} from 'reactstrap';
import Modal from 'components/Modal';

import './OptinFormMasterclass.scss';

export default function OptinFormMasterclass({
  id,
  contactFormHeader = null,
  linkColor = 'purple',
  variant = 'default',
  btnText = null,
  onComplete = () => {},
  ...props
}) {
  return (
    <ContactInfoForm
      onComplete={() => {
        onComplete();
      }}
      id={id}
      variant={variant}
      contactFormHeader={contactFormHeader}
      optinButtonText={btnText}
    />
  );
}

export function OptinFormMasterclassInline({
  variant = 'default',
  entry = 'default',
  disableNavigate = false,
  contactFormHeader = null,
  btnText = null,
  ...props
}) {
  const navigateUrl = '/masterclass/watch/';
  const onComplete = React.useCallback(() => {
    !disableNavigate && navigate(navigateUrl, {state: {fromEntry: entry}});
  }, []);
  return (
    <OptinFormMasterclass
      onComplete={onComplete}
      id="inlineOptInFormMasterclass"
      entry={entry}
      variant={variant}
      contactFormHeader={contactFormHeader}
      btnText={btnText}
    />
  );
}

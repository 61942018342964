import React from 'react';
import {Location} from '@reach/router';
import {Col, Container, Row, Button} from 'reactstrap';
import {Link} from 'react-scroll';
import classNames from 'classnames';
import qs from 'qs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCloudArrowDown} from '@fortawesome/pro-regular-svg-icons';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section from 'components/Elements/Section';
import Video from 'components/Video';
import VideoButtons from 'components/Broadcasts/VideoButtons';
import ClosedCaptionModal from 'components/Broadcasts/ClosedCaptionModal';
import CheckoutButton from 'components/CheckoutButton';
import {ImageCard} from 'components/Elements/ImgBullet';
import CommentSection from 'components/CommentSection';
import Footer from 'components/Elements/Footer';

//@ts-ignore
import {days} from 'data/pbcc-workshop.json';

export default (props) => (
  <Location>
    {({location}) => {
      const addProps = {};
      if (location && location.search) {
        const {day} = qs.parse(location.search, {ignoreQueryPrefix: true});
        //@ts-ignore
        if (day && isNaN(parseInt(location.search.day))) addProps.day = parseInt(day);
      }
      return <ReplaysIndex {...props} {...addProps} location={location} />;
    }}
  </Location>
);

export function ReplaysIndex({
  day = null,
  location,
  allReplaysOverride = null,
  timerOverride = null,
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = null,
  ctaNoTimer = false,
  variant = 'default',
  ...props
}) {
  const pageTitle = 'Plant-Based Coaching Workshop: A Live 3-Day Workshop';
  const pageDescription =
    'Learn the intricacies of nutrition, gain industry insights, and equip yourself to help people and the planet thrive.';
  const ogImage = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-workshop-broadcast-1200x1200.png';
  const shareUrl = 'https://certification.foodrevolution.org/workshop/';
  const facebookDescription =
    'Excited about plant-based living? Join our 3-day live workshop to transform your passion into a purposeful career. Learn from the best, champion health, and drive the food revolution. Register for free here.';
  const twitterDescription =
    'Join us for a transformative 3-day workshop on #PlantBasedCoaching with Ocean Robbins. Let’s shape a healthier world together. Register for free here.';
  const twitterImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-workshop-broadcast-1200x675.png';
  const pinterestDescription =
    'Discover the world of plant-based coaching with our immersive 3-day workshop. Empower yourself with knowledge, network with the experts, and set forth on a rewarding career path. Pin to inspire others to embark on this journey! Register for free here.';
  const pinterestImg = 'https://cdn.foodrevolution.org/pbcc/og/pbcc-2024-open-graph-workshop-broadcast-1000x1500.png';

  const {
    phases: {
      default: defaultSchedule = {phase: 'before'},
      workshop: workshopSchedule = {phase: 'before'},
      broadcast: broadcastSchedule = {phase: 'before', day: 0, message: {color: '', text: ''}},
      replay: replaySchedule = {phase: 'before'}
    }
  } = usePhaseState();

  const workshopPhase = workshopSchedule.phase;
  const broadcastPhase = broadcastSchedule.phase;
  const workshopReplayAll = workshopSchedule.workshopReplayAll;
  const clarityCall = defaultSchedule.clarityCall;

  day = day === null || day > replaySchedule.day ? replaySchedule.day : day;
  const replayPhase = day == replaySchedule.day ? replaySchedule.phase : 'after';
  const {replayUrl} = day == replaySchedule.day ? replaySchedule : days[day];

  const afterWorkshop = workshopPhase === 'after';
  const commentsUrl = 'https://certification.foodrevolution.org/workshop/broadcasts/#pbccw2024';

  const productSku = 'pbcc';
  const productSlug = 'pbcc-2025-deposit';
  const productValue = 100;

  useBalanceText();
  return (
    <div className="page d-flex flex-column page-nosocial">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div className="page-content">
        <Header
          bgColor="black"
          lockupWhite
          logoUrlOverride="https://cdn.foodrevolution.org/pbcc/pbcc-logo-workshop-white.svg"
          logoAltOverride="plant-based coaching workshop logo"
          logoWidthOverride={300}
          logoHeightOverride={59}
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />

        <Section id="" color="purple" innerClass="text-center text-white py-4" angle="none">
          <Container>
            <Row>
              <Col>
                <h1 className="section-heading text-h2 mb-2">
                  Food Revolution Network’s Plant-Based Coaching Workshop Replays
                </h1>
                <h2 className="text-h3 mt-0 mb-3">
                  <i>The Recipe for Real and Lasting Change</i>
                </h2>
                <h4 className="mt-0 mb-0">
                  {workshopReplayAll ? (
                    <>All Sessions are on Replay Below</>
                  ) : (
                    <>Join the Revolution LIVE August 23–25, 2024</>
                  )}
                </h4>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="" color="white" className="section-angle-first" innerClass="pt-0 pt-sm-4 pt-lg-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={afterWorkshop ? '12' : '10'} className="video-col px-0 px-sm-3">
                {afterWorkshop || (workshopPhase === 'during' && workshopReplayAll) ? (
                  <>
                    {workshopReplayAll ? (
                      <>
                        {days.map((day, i) => {
                          return (
                            <div key={'workshop-day-' + day.number} className={i === '2' ? 'mb-0' : 'mb-5'}>
                              <h3 className="section-heading text-center mb-3">
                                Day {day.number}: {day.name}
                              </h3>
                              <p className="balance-text text-center mb-4">{day.description}</p>
                              <Video url={day.replayVideo} label="PBCC-W - Broadcasts - Video" />
                              <Row className="align-items-center mt-2">
                                <Col xs="12" className="mb-2">
                                  <Button
                                    color="workshop-download"
                                    size="sm"
                                    href={day.downloads.transcript.url}
                                    target="_blank"
                                    block
                                  >
                                    <FontAwesomeIcon icon={faCloudArrowDown} /> {day.downloads.transcript.buttonText}
                                  </Button>
                                </Col>
                                <Col xs="12" className="mb-2">
                                  <Button
                                    color="workshop-download"
                                    size="sm"
                                    href={day.downloads.handoutOne.url}
                                    target="_blank"
                                    block
                                  >
                                    <FontAwesomeIcon icon={faCloudArrowDown} /> {day.downloads.handoutOne.buttonText}
                                  </Button>
                                </Col>
                                <Col xs="12" className="">
                                  <Button
                                    color="workshop-download"
                                    size="sm"
                                    href={day.downloads.handoutTwo.url}
                                    target="_blank"
                                    block
                                  >
                                    <FontAwesomeIcon icon={faCloudArrowDown} /> {day.downloads.handoutTwo.buttonText}
                                  </Button>
                                </Col>
                              </Row>
                              <div
                                className={classNames(
                                  `video-buttons`,
                                  `text-center`,
                                  `d-flex`,
                                  `align-items-center`,
                                  `justify-content-center`,
                                  `mt-2`
                                )}
                              >
                                <Link
                                  className="btn btn-medium-gray text-16 text-800 text-white px-4 py-2 mr-2"
                                  activeClass="active"
                                  spy={true}
                                  offset={0}
                                  to="comments"
                                  smooth={true}
                                  href="#"
                                >
                                  Comment Now
                                </Link>
                                <ClosedCaptionModal />
                              </div>
                            </div>
                          );
                        })}
                        <p className="text-center mt-4 mb-2">
                          <b>Capture every takeaway with this fillable Workshop Workbook:</b>
                        </p>
                        <p className="text-center">
                          <Button
                            href="https://cdn.foodrevolution.org/members/pdf/pbcc/Plant-Based%20Coaching%20Workshop%20-%20Workbook.pdf"
                            target="_blank"
                            color="cta"
                            size="sm"
                          >
                            Workbook Download Here
                          </Button>
                        </p>
                        <p className="text-center balance-text mt-5">
                          The Plant-Based Coaching Workshop has concluded, but we’re excited to announce that we are
                          enrolling for the Plant-Based Coaching Certification through January 8, 2025, or until we have
                          reached capacity. To learn more about the program and apply, visit{' '}
                          <a href="/join/">https://certification.foodrevolution.org/join/</a>.
                        </p>
                      </>
                    ) : (
                      <div className="my-4 background-light-gray box-shadow-black-50 rounded p-4 p-lg-5 text-center">
                        <h5 className="section-only-heading text-400">
                          The Plant-Based Coaching Workshop has concluded, but we’re excited to announce that we are
                          enrolling for the Plant-Based Coaching Certification through January 8, 2025, or until we have
                          reached capacity. To learn more about the program and apply, visit{' '}
                          <a href="/join/">https://certification.foodrevolution.org/join/</a>.
                        </h5>
                      </div>
                    )}
                  </>
                ) : workshopPhase === 'during' && replayPhase != 'after' && broadcastPhase === 'during' ? (
                  <>
                    <h4 className="section-heading">Click below to access the Workshop session going on now:</h4>
                    <Button color="cta" size="lg" href="/workshop/broadcasts/">
                      Watch Now!
                    </Button>
                  </>
                ) : (workshopPhase === 'during' && replayPhase === 'after' && !replayUrl) ||
                  (workshopPhase === 'during' && broadcastPhase === 'after' && replayPhase != 'after') ? (
                  <div className="background-light-gray box-shadow-black-50 rounded p-4 p-lg-5">
                    <h5 className="m-0">Today’s replay will be available soon!</h5>
                  </div>
                ) : workshopPhase === 'during' && replayPhase === 'after' && replayUrl ? (
                  <>
                    <h3 className="section-heading">
                      Replay for Day {days[day].number}: {days[day].name}
                    </h3>
                    <Video url={replayUrl} />
                    <VideoButtons className="mt-2" replay />
                  </>
                ) : (
                  <>
                    <h4 className="section-heading">Join the Revolution LIVE August 23–25, 2024</h4>
                    <Button color="cta" size="lg" href="/workshop/broadcasts/">
                      Join Us!
                    </Button>
                  </>
                )}

                {!afterWorkshop && (
                  <>
                    {!workshopReplayAll && (
                      <>
                        {' '}
                        <p className="balance-text">
                          <b>How It Works:</b> Each day of the workshop, we will broadcast a new session starting at 12
                          pm ET / 9 am PT / 4 pm GMT. After each day’s screening ends, the workshop will be available on{' '}
                          <a href="/workshop/replays" target="_blank">
                            replay
                          </a>{' '}
                          for a day. Each session will run for about three hours.
                        </p>
                        <p className="balance-text">
                          Join Food Revolution Network’s Ocean Robbins, Tracy Garrigan, CHHC, Nichole Dandrea-Russert,
                          RDN, and special guest experts for a live, three-day workshop where you’ll learn about
                          plant-based nutrition, coaching, and how to share this information — so you can help people
                          heal their lives, and turn your passion for plant-based eating into a deeply rewarding
                          vocation.
                        </p>
                        <h6 className="mt-4 mb-3">Click Here to Add These Events to Your Calendar</h6>
                        <Row className="text-center align-items-center">
                          <Col lg="4" className="mb-4 mb-lg-0">
                            <p className="mb-2">
                              <b>Friday, August 23rd</b>
                            </p>
                            <Button
                              title="Add to Calendar"
                              className="addeventatc"
                              data-id="kn22228380"
                              href="https://www.addevent.com/event/kn22228380"
                              target="_blank"
                              color="secondary"
                            >
                              Add to Calendar
                            </Button>
                          </Col>
                          <Col lg="4" className="mb-4 mb-lg-0">
                            <p className="mb-2">
                              <b>Saturday, August 24th</b>
                            </p>
                            <Button
                              title="Add to Calendar"
                              className="addeventatc"
                              data-id="IR22228381"
                              href="https://www.addevent.com/event/IR22228381"
                              target="_blank"
                              color="secondary"
                            >
                              Add to Calendar
                            </Button>
                          </Col>
                          <Col lg="4">
                            <p className="mb-2">
                              <b>Sunday, August 25th</b>
                            </p>
                            <Button
                              title="Add to Calendar"
                              className="addeventatc"
                              data-id="eZ22228382"
                              href="https://www.addevent.com/event/eZ22228382"
                              target="_blank"
                              color="secondary"
                            >
                              Add to Calendar
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Section>

        {!afterWorkshop || workshopReplayAll ? (
          <Section id="" color="light-gray">
            <Container>
              <Row className="align-items-stretch">
                <ImageCard
                  img="https://cdn.foodrevolution.org/pbcc/images/1409236261.jpg"
                  colLg="4"
                  contentClassOverride="d-flex flex-column flex-grow-1 text-left"
                >
                  <h5 className="section-heading text-center mb-3">Day One: Plant-Based Nutrition</h5>
                  <p className="text-16">We’ll share:</p>
                  <ul className="text-16">
                    <li>Key advantages and potential pitfalls of a plant-based diet</li>
                    <li>How to maximize the benefits and tips to maximize positive outcomes</li>
                    <li>The FRN approach to embracing a whole-food plant-based lifestyle</li>
                  </ul>
                </ImageCard>
                <ImageCard
                  img="https://cdn.foodrevolution.org/pbcc/images/1411238326.jpg"
                  colLg="4"
                  contentClassOverride="d-flex flex-column flex-grow-1 text-left"
                >
                  <h5 className="section-heading text-center mb-3">Day Two: Coaching</h5>
                  <p className="text-16">Discover:</p>
                  <ul className="text-16">
                    <li>How structure paves the way for client success</li>
                    <li>FRN’s essential coaching skills and unique techniques</li>
                    <li>The magic of coaching in action with an engaging live demonstration</li>
                  </ul>
                </ImageCard>
                <ImageCard
                  img="https://cdn.foodrevolution.org/pbcc/images/1198696330.jpg"
                  colLg="4"
                  contentClassOverride="d-flex flex-column flex-grow-1 text-left"
                >
                  <h5 className="section-heading text-center mb-3">Day Three: Impact</h5>
                  <ul className="text-16">
                    <li>Learn more about the growing plant-based living movement</li>
                    <li>Become a catalyst for healing</li>
                    <li>
                      Unleash your potential by exploring dynamic coaching pathways—whether you start your own business,
                      find fulfilling employment, or both
                    </li>
                    <li>Learn powerful grassroots strategies to make a lasting impact on your family and community.</li>
                  </ul>
                </ImageCard>
              </Row>
            </Container>
          </Section>
        ) : null}

        <Section id="pbccw-cta" color="purple" className="text-center text-white">
          <Container>
            <Row>
              <Col>
                <h4 className="section-heading">Bring the Food Revolution to the World as a Plant-Based Coach</h4>
                <p className="balance-text">
                  Are you looking for a dream job where you get to do deeply rewarding work? Or would you like to find
                  out how to make a difference as a plant-based advocate?
                </p>
                <p className="balance-text mb-0">
                  Turn your love of plant-based foods into a thriving vocation where you get to help people and help the
                  planet.
                </p>
                <div>
                  <img
                    className="my-4"
                    src="https://cdn.foodrevolution.org/pbcc/pbcc-logo-white.svg"
                    alt="plant-based coaching certification logo"
                    style={{maxHeight: '100px'}}
                  />
                </div>
                {!afterWorkshop && (
                  <>
                    <h5 className="text-400 mt-0 mb-2">
                      Apply before 11:59 pm Eastern on August 26th to be eligible for:
                    </h5>
                    <p className="mb-4">
                      Special Tuition Discount (Save up to $1,585)
                      <br />
                      Unlimited Membership in WHOLE Life Club (Value $497/year)
                      <br />
                      Ocean Robbins’ Food Revolution Mastermind (Value $3,000)
                      <br />
                      2020–2025 Food Revolution Summit Empowerment Packages (Value $1,182)
                    </p>
                  </>
                )}
                <p>
                  <Button href="/join/" color="cta" target="_blank" size="lg">
                    Learn More
                  </Button>
                </p>
                <h3 className="mt-5 mb-3">Ready to Apply Now? Click the button below.</h3>
                <p>
                  <CheckoutButton sku={productSku} price={productValue} slug={productSlug} target="_blank">
                    Save My Spot
                  </CheckoutButton>
                </p>
                <p className="text-small">
                  This will take you to a page to submit your $100 refundable deposit, and then you can apply. Your
                  deposit counts towards your total tuition. If your application is accepted, your space will be held
                  for two weeks, so you can submit payment. The application takes about 10 minutes to complete.
                </p>
                {clarityCall && (
                  <>
                    <h3 className="mt-5 mb-3">Have questions? Speak with a program advisor.</h3>
                    <p>
                      <Button href="/clarity-calls/" color="cta" target="_blank" size="lg">
                        Book A Clarity Call
                      </Button>
                    </p>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Section>

        <CommentSection
          id="comments"
          numPosts="10"
          url={commentsUrl}
          isBroadcastPage
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImg}
        />
      </div>

      <div className="footer-content mt-auto">
        <Footer />
      </div>
      <script type="text/javascript" src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js" async defer></script>
    </div>
  );
}

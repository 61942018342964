import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {Link} from 'react-scroll';
import classNames from 'classnames';
import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent';
import {ListIcon} from 'components/Elements/IconList';
import useBalanceText from 'utils/useBalanceText';

export default function ModalExitSales() {
  const {isOpen, toggle} = useExitIntent(60000);
  return (
    <Modal className="modal-exit-intent modal-exit-intent-empowerment" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h5 className="section-heading text-center">
            You’re an essential part <br className="d-none d-xl-block" />
            of the food revolution.
          </h5>
          <p className="text-center">
            We can help you create <br className="d-none d-xl-block" />
            the change you want to see.
          </p>
          <ListIcon className="my-4">
            <>Gain the critical nutrition wisdom you need to be a credible plant-based eating mentor</>
            <>Learn how to successfully help others to implement lasting positive changes and promote vibrant health</>
            <>Make a real impact with a career path or vocation that allows you to be a powerful force for good</>
          </ListIcon>
          <Button
            className="btn-exit text-uppercase text-white text-h5"
            color="cta"
            onClick={toggle}
            block
            activeClass="active"
            spy={true}
            offset={-110}
            to="pbcc-cta-2"
            smooth={true}
            href="#"
            tag={Link}
          >
            Apply Now
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div
            className={classNames(`exit-modal-image`)}
            style={{
              backgroundImage: `url('https://cdn.foodrevolution.org/pbcc/images/1256729587-exit.jpg')`,
              backgroundPosition: 'center top'
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
}

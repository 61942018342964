

  
// Template Map
export default {
  '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404': require('/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/workshop/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/workshop/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-li/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-li/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-li/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-li/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-li/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-li/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-li/workshop/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-li/workshop/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/workshop/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/workshop/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/clarity-calls.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/clarity-calls.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/replay.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/replay.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/watch.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/watch.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/broadcasts.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/broadcasts.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/workshop/replays.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/workshop/replays.jsx').default
}

export const notFoundTemplate = '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404'

